import React, { useContext, useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import logo from '../../Assets/images/couponesta.jpg'
import { jwtDecode } from 'jwt-decode';
import { UserContext } from '../../Context/UserContext.js';

export default function Navbar() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    // let { setRefresh_token, setUserData } = useContext(UserContext)

    let { setRefresh_token } = useContext(UserContext)
    let navigate = useNavigate()

    // location
    const [dropdownOpenButton, setDropdownOpenButton] = useState(false);

    const toggleDropdownButton1 = () => {
        setDropdownOpenButton(!dropdownOpenButton);
    };

    // category
    const [dropdownOpenButton2, setDropdownOpenButton2] = useState(false);

    const toggleDropdownButton2 = () => {
        setDropdownOpenButton2(!dropdownOpenButton2);
    };

    // brand
    const [dropdownOpenButton3, setDropdownOpenButton3] = useState(false);

    const toggleDropdownButton3 = () => {
        setDropdownOpenButton3(!dropdownOpenButton3);
    };

    // coupon
    const [dropdownOpenButton4, setDropdownOpenButton4] = useState(false);

    const toggleDropdownButton4 = () => {
        setDropdownOpenButton4(!dropdownOpenButton4);
    };

    // notification
    const [dropdownOpenButton5, setDropdownOpenButton5] = useState(false);

    const toggleDropdownButton5 = () => {
        setDropdownOpenButton5(!dropdownOpenButton5);
    };

    // ads
    const [dropdownOpenButton6, setDropdownOpenButton6] = useState(false);

    const toggleDropdownButton6 = () => {
        setDropdownOpenButton6(!dropdownOpenButton6);
    };

    // users
    const [dropdownOpenButton7, setDropdownOpenButton7] = useState(false);

    const toggleDropdownButton7 = () => {
        setDropdownOpenButton7(!dropdownOpenButton7);
    };

    let decoded = {}

    if (localStorage.getItem('refresh_token') !== null) {

        decoded = jwtDecode(localStorage.getItem('refresh_token'))
    }


    const [userData, setUserData] = useState(() => {
        const storedUserData = localStorage.getItem('userAccount');
        return storedUserData ? JSON.parse(storedUserData) : {};
    });

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'userAccount') {
                setUserData(event.newValue ? JSON.parse(event.newValue) : {});
            }
        };

        window.addEventListener('storage', handleStorageChange);

        // Set initial user data from localStorage
        const storedUserData = localStorage.getItem('userAccount');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
        }

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);


    function logOut() {
        localStorage.removeItem('refresh_token');
        setRefresh_token(null)
        setUserData(null)
        navigate('/login')
    }

    // change title
    const location = useLocation();
    const [title, setTitle] = useState('Couponesta Dashboard');

    useEffect(() => {

        const path = location.pathname.split('/')[1]; // Extracting the first segment of the path

        switch (path) {
            case '':
                setTitle('Couponesta Dashboard');
                break;
            case 'location':
                setTitle('Locations');
                break;
            case 'locationdeleted':
                setTitle('Deleted Locations');
                break;
            case 'category':
                setTitle('Category');
                break;
            case 'categorydeleted':
                setTitle('Deleted Categories');
                break;
            case 'brand':
                setTitle('Brands');
                break;
            case 'favoritebrand':
                setTitle('Favorite Brands');
                break;
            case 'branddeleted':
                setTitle('Deleted Brands');
                break;
            case 'ads':
                setTitle('Ads');
                break;
            case 'adsdeleted':
                setTitle('Deleted Ads');
                break;
            case 'coupon':
                setTitle('Coupons');
                break;
            case 'coupondeleted':
                setTitle('Deleted Coupons');
                break;
            case 'notification':
                setTitle('Notifications');
                break;
            case 'notificationdeleted':
                setTitle('Deleted Notifications');
                break;
            case 'user':
                setTitle('Users');
                break;
            case 'userdetails':
                setTitle('User Details');
                break;
            case 'userdeleted':
                setTitle('Deleted Users');
                break;
            case 'barchar':
                setTitle('Bar Chart');
                break;
            case 'piechar':
                setTitle('Pie Chart');
                break;
            default:
                setTitle('Page Not Found');
        }
    }, [location.pathname]);

    return <>

        <div className="flex h-screen overflow-x-auto bg-gray-100 dark:bg-gray-900">

            <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                <div className="px-3 py-3 lg:px-5 lg:pl-3">
                    <div className="flex items-center justify-between">

                        <div className="flex items-center justify-start w-full rtl:justify-end">

                            <button
                                onClick={() => setSidebarOpen(!sidebarOpen)}
                                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            >
                                <span className="sr-only">Open sidebar</span>

                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        clipRule="evenodd"
                                        fillRule="evenodd"
                                        d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                                    ></path>
                                </svg>
                            </button>

                            <div className="flex ms-2 md:me-28">
                                <Link to="/" className=''>
                                    <img src={logo} className="h-8 me-3" alt="Couponesta Logo" />
                                </Link>
                                <span className="self-center text-xl font-serif text-teal-600 font-semibold md:text-2xl whitespace-nowrap dark:text-white">
                                    {title || "Couponesta Dashboard"}
                                </span>
                            </div>

                        </div>

                        <div className="flex  items-center">
                            <div className="flex items-center ms-3">
                                <div>
                                    <button
                                        type="button"
                                        className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                        aria-expanded={dropdownOpen}
                                        onClick={() => setDropdownOpen(!dropdownOpen)}
                                    >
                                        <span className="sr-only">Open user menu</span>
                                        <img className="w-8 h-8 xl:w-10 xl:h-10 rounded-full object-contain" src={userData.image ? userData.image : logo} alt="user photo" />
                                    </button>
                                </div>

                                <div
                                    className={`${dropdownOpen ? 'block absolute right-2 top-8 xl:top-10' : 'hidden'
                                        } z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600`}
                                    id="dropdown-user"
                                >
                                    <div className="px-4 py-3" role="none">
                                        <p className="text-sm text-gray-900 dark:text-white" role="none">
                                            {userData.fullName || decoded.fullName || "Couponesta"}
                                        </p>
                                        <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300" role="none">
                                            {userData.email || decoded.email || "Couponesta@gmail.com"}
                                        </p>
                                    </div>

                                    <ul className="py-1" role="none">
                                        <li>
                                            <Link to="/" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Dashboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/login" onClick={() => logOut()} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Sign out</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </nav>

            <aside
                className={`fixed top-0 left-0 z-40 h-screen pt-16 transition-transform bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700 ${sidebarOpen ? 'translate-x-0 w-64' : '-translate-x-full'
                    }`}
                aria-label="Sidebar"
            >
                <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">

                    <div className="text-center py-2 mx-auto grayscale-0 drop-shadow-md">
                        <img className="h-28 w-28 rounded-full mx-auto inline-block object-contain" src={userData?.image} alt="user photo" />

                        <div className="mt-2">
                            <p className="font-semibold text-teal-700 dark:text-white">
                                {userData.fullName || decoded.fullName || "Couponesta"}
                            </p>
                            <p className="font-semibold my-2 text-nowrap text-gray-800 truncate dark:text-gray-300">
                                {userData.email || decoded.email || "Couponesta@gmail.com"}
                            </p>
                        </div>

                    </div>

                    <ul className="space-y-2 font-medium border-t-2 pt-1 border-dotted border-teal-400">

                        {/* home */}
                        <li>
                            <button
                                type="button"
                                className={` ${title == "Couponesta Dashboard" ? 'bg-teal-700 bg-opacity-30 text-gray-900' : 'hover:bg-gray-100 hover:text-gray-900 text-gray-500  dark:text-white dark:hover:bg-gray-700'} flex items-center w-full mt-1 p-2 text-base transition duration-75 rounded-lg group`}
                                aria-controls="dropdown-example"
                            >
                                <i className="fa-solid fa-home flex-shrink-0 w-5 h-5  transition duration-75  dark:text-gray-400 dark:group-hover:text-white" aria-hidden="true"></i>
                                <Link to={'/'} className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-gray-900">Home</Link>

                                <i className="w-3 h-0 fa-xl fa-solid fa-caret-left flex-shrink-0  transition duration-75  dark:text-gray-400 dark:group-hover:text-white"></i>
                            </button>
                        </li>

                        {/* location */}
                        <li>
                            <button
                                type="button"
                                className={` ${title == "Locations" ? 'bg-teal-700 bg-opacity-30 text-gray-900' : 'hover:bg-gray-100 hover:text-gray-900 text-gray-500  dark:text-white dark:hover:bg-gray-700'} flex items-center w-full mt-1 p-2 text-base transition duration-75 rounded-lg group`}
                                aria-controls="dropdown-example"
                                onClick={toggleDropdownButton1}
                            >
                                <i className="fa-solid fa-globe flex-shrink-0 w-5 h-5  transition duration-75 dark:text-gray-400 dark:group-hover:text-white" aria-hidden="true"></i>
                                <Link to={'/location'} className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-gray-900">Location</Link>

                                {dropdownOpenButton ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-left flex-shrink-0  transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"></i>}
                            </button>
                            <ul id="dropdown-example" className={`py-2 space-y-2 ${dropdownOpenButton ? 'block' : 'hidden'}`}>
                                <li>
                                    <Link to={'/locationdeleted'} className={` ${title == "Deleted Locations" ? 'bg-teal-700 bg-opacity-30' : 'hover:bg-gray-100'} flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group dark:text-white dark:hover:bg-gray-700`} >Recycle Bin Location</Link>
                                </li>
                            </ul>
                        </li>

                        {/* category */}
                        <li>
                            <button
                                type="button"
                                className={` ${title == "Category" ? 'bg-teal-700 bg-opacity-30 text-gray-900' : 'hover:bg-gray-100 hover:text-gray-900 text-gray-500  dark:text-white dark:hover:bg-gray-700'} flex items-center w-full mt-1 p-2 text-base transition duration-75 rounded-lg group`}
                                aria-controls="dropdown-example"
                                onClick={toggleDropdownButton2}
                            >
                                <i className="fa-solid fa-layer-group flex-shrink-0 w-5 h-5  transition duration-75 " aria-hidden="true"></i>
                                <Link to={'/category'} className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-gray-900">Category</Link>

                                {dropdownOpenButton2 ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-left flex-shrink-0 transition duration-75 "></i>}
                            </button>
                            <ul id="dropdown-example" className={`py-2 mb-5 space-y-2 ${dropdownOpenButton2 ? 'block' : 'hidden'}`}>
                                <li>
                                    <Link to={'/categorydeleted'} className={` ${title == "Deleted Categories" ? 'bg-teal-700 bg-opacity-30' : 'hover:bg-gray-100'} flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group dark:text-white dark:hover:bg-gray-700`}>Recycle Bin Category</Link>
                                </li>
                            </ul>
                        </li>

                        {/* brand */}
                        <li>
                            <button
                                type="button"
                                className={` ${title == "Brands" ? 'bg-teal-700 bg-opacity-30 text-gray-900' : 'hover:bg-gray-100 hover:text-gray-900 text-gray-500  dark:text-white dark:hover:bg-gray-700'} flex items-center w-full mt-1 p-2 text-base transition duration-75 rounded-lg group`}
                                aria-controls="dropdown-example"
                                onClick={toggleDropdownButton3}
                            >
                                <i className="fa-brands fa-shopify flex-shrink-0 w-5 h-5 transition duration-75 " aria-hidden="true"></i>
                                <Link to={'/brand'} className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-gray-900">Brands</Link>

                                {dropdownOpenButton3 ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-left flex-shrink-0 transition duration-75 "></i>}
                            </button>
                            <ul id="dropdown-example" className={`py-2 mb-5 space-y-2 ${dropdownOpenButton3 ? 'block' : 'hidden'}`}>
                                <li>
                                    <Link to={'/favoritebrand'} className={` ${title == "Favorite Brands" ? 'bg-teal-700 bg-opacity-30' : 'hover:bg-gray-100'} flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group dark:text-white dark:hover:bg-gray-700`}>Favorite Brand</Link>
                                </li>
                                <li>
                                    <Link to={'/branddeleted'} className={` ${title == "Deleted Brands" ? 'bg-teal-700 bg-opacity-30' : 'hover:bg-gray-100'} flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group dark:text-white dark:hover:bg-gray-700`}>Recycle Bin Brand</Link>
                                </li>
                            </ul>
                        </li>

                        {/* coupons */}
                        <li>
                            <button
                                type="button"
                                className={` ${title == "Coupons" ? 'bg-teal-700 bg-opacity-30 text-gray-900' : 'hover:bg-gray-100 hover:text-gray-900 text-gray-500  dark:text-white dark:hover:bg-gray-700'} flex items-center w-full mt-1 p-2 text-base transition duration-75 rounded-lg group`}
                                aria-controls="dropdown-example"
                                onClick={toggleDropdownButton4}
                            >
                                <i className="fa-solid fa-ticket flex-shrink-0 w-5 h-5 transition duration-75 " aria-hidden="true"></i>
                                <Link to={'/coupon'} className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-gray-900">Coupons</Link>

                                {dropdownOpenButton4 ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-left flex-shrink-0 text-gray-500 transition duration-75 "></i>}
                            </button>
                            <ul id="dropdown-example" className={`py-2 mb-5 space-y-2 ${dropdownOpenButton4 ? 'block' : 'hidden'}`}>
                                <li>
                                    <Link to={'/coupondeleted'} className={` ${title == "Deleted Coupons" ? 'bg-teal-700 bg-opacity-30' : 'hover:bg-gray-100'} flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group  dark:text-white dark:hover:bg-gray-700`}>Recycle Bin Coupon</Link>
                                </li>

                            </ul>
                        </li>

                        {/* notification */}
                        <li>
                            <button
                                type="button"
                                className={` ${title == "Notifications" ? 'bg-teal-700 bg-opacity-30 text-gray-900' : 'hover:bg-gray-100 hover:text-gray-900 text-gray-500  dark:text-white dark:hover:bg-gray-700'} flex items-center w-full mt-1 p-2 text-base transition duration-75 rounded-lg group`}
                                aria-controls="dropdown-example"
                                onClick={toggleDropdownButton5}
                            >
                                <i className="fa-solid fa-bell flex-shrink-0 w-5 h-5 transition duration-75 " aria-hidden="true"></i>
                                <Link to={'/notification'} className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-gray-900">Notifications</Link>

                                {dropdownOpenButton5 ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-left flex-shrink-0 transition duration-75 "></i>}
                            </button>
                            <ul id="dropdown-example" className={`py-2 mb-5 space-y-2 ${dropdownOpenButton5 ? 'block' : 'hidden'}`}>
                                <li>
                                    <Link to={'/notificationdeleted'} className={` ${title == "Deleted Notifications" ? 'bg-teal-700 bg-opacity-30' : 'hover:bg-gray-100'} flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group dark:text-white dark:hover:bg-gray-700`}>Recycle Bin Notification</Link>
                                </li>
                            </ul>
                        </li>

                        {/* Ads */}
                        <li>
                            <button
                                type="button"
                                className={` ${title == "Ads" ? 'bg-teal-700 bg-opacity-30 text-gray-900' : 'hover:bg-gray-100 hover:text-gray-900 text-gray-500  dark:text-white dark:hover:bg-gray-700'} flex items-center w-full mt-1 p-2 text-base transition duration-75 rounded-lg group`}
                                aria-controls="dropdown-example"
                                onClick={toggleDropdownButton6}
                            >
                                <i className="fa-brands fa-adversal flex-shrink-0 w-5 h-5 transition duration-75 " aria-hidden="true"></i>
                                <Link to={'/ads'} className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-gray-900">Ads</Link>

                                {dropdownOpenButton6 ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-left flex-shrink-0 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"></i>}
                            </button>
                            <ul id="dropdown-example" className={`py-2 mb-5 space-y-2 ${dropdownOpenButton6 ? 'block' : 'hidden'}`}>
                                <li>
                                    <Link to={'/adsdeleted'} className={` ${title == "Deleted Ads" ? 'bg-teal-700 bg-opacity-30' : 'hover:bg-gray-100'} flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group dark:text-white dark:hover:bg-gray-700`}>Recycle Bin Ads</Link>
                                </li>
                            </ul>
                        </li>

                        {/* users */}
                        <li>
                            <button
                                type="button"
                                className={` ${title == "Users" ? 'bg-teal-700 bg-opacity-30 text-gray-900' : 'hover:bg-gray-100 hover:text-gray-900 text-gray-500  dark:text-white dark:hover:bg-gray-700'} flex items-center w-full mt-1 p-2 text-base transition duration-75 rounded-lg group`}
                                aria-controls="dropdown-example"
                                onClick={toggleDropdownButton7}
                            >
                                <i className="fa-solid fa-users flex-shrink-0 w-5 h-5 transition duration-75 " aria-hidden="true"></i>
                                <Link to={'/user'} className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-gray-900">Users</Link>

                                {dropdownOpenButton7 ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-left flex-shrink-0 transition duration-75 "></i>}
                            </button>
                            <ul id="dropdown-example" className={`py-2 mb-5 space-y-2 ${dropdownOpenButton7 ? 'block' : 'hidden'}`}>
                                <li>
                                    <Link to={'/userdetails'} className={` ${title == "User Details" ? 'bg-teal-700 bg-opacity-30' : 'hover:bg-gray-100'} flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group dark:text-white dark:hover:bg-gray-700`}>Details Users</Link>
                                </li>
                                <li>
                                    <Link to={'/userdeleted'} className={` ${title == "Deleted Users" ? 'bg-teal-700 bg-opacity-30' : 'hover:bg-gray-100'} flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group dark:text-white dark:hover:bg-gray-700`}>Recycle Bin Users</Link>
                                </li>
                            </ul>
                        </li>

                        {/* Bar char */}
                        <li>
                            <button
                                type="button"
                                className={` ${title == "Bar Chart" ? 'bg-teal-700 bg-opacity-30 text-gray-900' : 'hover:bg-gray-100 hover:text-gray-900 text-gray-500  dark:text-white dark:hover:bg-gray-700'} flex items-center w-full mt-1 p-2 text-base transition duration-75 rounded-lg group`}
                                aria-controls="dropdown-example"
                            >
                                <i className="fa-solid fa-chart-column flex-shrink-0 w-5 h-5 transition duration-75 " aria-hidden="true"></i>
                                <Link to={'/barchar'} className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-gray-900">Bar Char</Link>

                                <i className="w-3 h-0 fa-xl fa-solid fa-caret-left flex-shrink-0 transition duration-75 "></i>
                            </button>
                        </li>

                        {/* Bie char */}
                        <li>
                            <button
                                type="button"
                                className={` ${title == "Pie Chart" ? 'bg-teal-700 bg-opacity-30 text-gray-900' : 'hover:bg-gray-100 hover:text-gray-900 text-gray-500  dark:text-white dark:hover:bg-gray-700'} flex items-center w-full mt-1 p-2 text-base transition duration-75 rounded-lg group`}
                                aria-controls="dropdown-example"
                            >
                                <i className="fa-solid fa-chart-pie flex-shrink-0 w-5 h-5 transition duration-75" aria-hidden="true"></i>
                                <Link to={'/piechar'} className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-gray-900">Pie Char</Link>

                                <i className="w-3 h-0 fa-xl fa-solid fa-caret-left flex-shrink-0 transition duration-75"></i>
                            </button>
                        </li>

                        {/* add more in side bar */}

                    </ul>
                </div>

            </aside >

            <div className={`p-4 w-screen overflow-y-auto mt-16 ${sidebarOpen ? 'md:ml-64' : ''}`}>
                {/* Content goes here */}
                <Outlet></Outlet>
            </div>
        </div >










        {/* </div> */}

    </>
}
