import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BallTriangle } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';


export default function Notification() {

    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [error, setError] = useState(null)
    const [errorUpdate, setErrorUpdate] = useState(null)

    const handleInputChange = (event) => { // Function to handle input change
        setSearchQuery(event.target.value); // Update searchQuery state with input value
    };

    async function getNotifications(searchQuery) {

        return await axios.get(`${process.env.REACT_APP_BACK_END}notification/all/toDashboard?search=${searchQuery}`,
            {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                }
            }
        )
    }

    let { isLoading, data, refetch } = useQuery(['Notifications', searchQuery], () => getNotifications(searchQuery), {
        cacheTime: 9000000
    })


    let allNotifications = []

    if (data?.data?.message == "success") {

        allNotifications = data?.data?.notification
    }


    // modal
    const toggleModal = () => {
        const modal = document.getElementById('crud-modal');
        modal.classList.toggle('hidden');
        modal.setAttribute('aria-hidden', modal.classList.contains('hidden'));
    };

    const toggleModalUpdate = () => {
        const modalUpdate = document?.getElementById('crud-modal-update');
        modalUpdate?.classList?.toggle('hidden');
        modalUpdate?.setAttribute('aria-hidden', modalUpdate?.classList?.contains('hidden'));
    };

    const toggleModelDel = () => {
        const modalDelete = document.getElementById('popup-modal');
        modalDelete.classList.toggle('hidden');
        modalDelete.setAttribute('aria-hidden', modalDelete.classList.contains('hidden'));
    }


    // add notification

    // user
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    useEffect(() => {
        async function fetchUsers() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACK_END}user`,
                    {
                        headers: {
                            authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                        },
                    }
                );

                setUsers(response?.data?.users);
            } catch (error) {
                // console.error('Error fetching users:', error);
            }
        }

        fetchUsers();
    }, []);


    const handleChangeUser = (event) => {
        const userId = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            setSelectedUsers([...selectedUsers, userId]);
        } else {
            setSelectedUsers(selectedUsers.filter(id => id !== userId));
        }
    };
    // end user


    // add Notifications
    async function submitAddNotification(values) {

        // Append selected users
        values.user = [...selectedUsers];

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACK_END}notification`,
                values,
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();
            formik.resetForm();
            toggleModal()
            setSelectedUsers([]); // Reset selected users
            toast.success('notification added successfully!');
        } catch (error) {
            // console.error('Error:', error?.response?.data);
            // toast.error(error?.response?.data?.message);
            setError(error?.response?.data?.message)
        }
    }

    let validateSchemaAdd = Yup.object({
        en_header: Yup.string().min(1, 'notification header EN is too short').required('notification header EN is required'),
        ar_header: Yup.string().min(1, 'notification header AR is too short').required('notification header AR is required'),
        en_body: Yup.string().min(1, 'notification body EN is too short').required('notification body EN is required'),
        ar_body: Yup.string().min(1, 'notification body AR is too short').required('notification body AR is required'),
        user: Yup.array().of(Yup.string().required()).required(),

    });

    let formik = useFormik({
        initialValues: {
            en_header: '',
            ar_header: '',
            en_body: '',
            ar_body: '',
            user: []
        },
        validationSchema: validateSchemaAdd,
        onSubmit: submitAddNotification
    });

    // end add notification


    // update notification
    const [updateNotificationData, setUpdateNotificationData] = useState(null);
    const [updatingNotificationId, setUpdatingNotificationId] = useState(null);

    // Function to handle update button click
    const handleUpdateClick = (notification) => {
        setUpdateNotificationData(notification); // Set the notification data to state
        setUpdatingNotificationId(notification?._id); // Set the ID of the notification being updated
        toggleModalUpdate(); // Show the update modal
    };

    async function submitUpdateNotification(values) {

        try {

            let userPrevious = [...updateNotificationData.user].map((user) => user._id);

            values.user = selectedUsers.length === 0 ? [...userPrevious] : [...selectedUsers];


            values.en_header = values.en_header || updateNotificationData.en_header
            values.ar_header = values.ar_header || updateNotificationData.ar_header
            values.en_body = values.en_body || updateNotificationData.en_body
            values.ar_body = values.ar_body || updateNotificationData.ar_body


            const response = await axios.put(
                `${process.env.REACT_APP_BACK_END}notification/${updatingNotificationId}`,
                values,
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );


            await refetch();
            formik.resetForm();
            toggleModalUpdate()
            setSelectedUsers([]); // Reset selected users
            toast.success('notification added successfully!');
        } catch (error) {
            // console.error('Error:', error?.response?.data);
            // toast.error(error?.response?.data?.message);
            setError(error?.response?.data?.message)
        }
    }


    let validateSchemaUpdate = Yup.object({
        en_header: Yup.string().min(1, 'notification header EN is too short'),
        ar_header: Yup.string().min(1, 'notification header AR is too short'),
        en_body: Yup.string().min(1, 'notification body EN is too short'),
        ar_body: Yup.string().min(1, 'notification body AR is too short'),
        user: Yup.array().of(Yup.string()),

    });

    let formikUpdate = useFormik({
        initialValues: {
            en_header: '',
            ar_header: '',
            en_body: '',
            ar_body: '',
            user: []
        },
        validationSchema: validateSchemaUpdate,
        onSubmit: submitUpdateNotification
    });

    // end update notification


    // delete notification
    const [deleteNotificationId, setDeleteNotificationId] = useState(null);

    // Function to handle update button click
    const handleDeleteClick = (notification) => {
        setDeleteNotificationId(notification?._id); // Set the ID of the notification being updated
        toggleModelDel(); // Show the update modal
    };

    // Function to submit update notification
    async function submitDeleteNotification() {

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACK_END}notification/${deleteNotificationId}`,
                { isDeleted: true },
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();
            toggleModelDel()
            toast.success('Notification Moved to Recycle bin successfully!');
        } catch (error) {
            // console.error('Error:', error?.response?.data?.message);
            // toast.error(error?.response?.data?.message);
            setError(error?.response?.data?.message)
        }
    }

    return <>


        <div className="p-6 pe-8 overflow-y-auto rounded-t-xl bg-blue-gray-100 dark:bg-gray-900 flex justify-between align-middle">
            <div className="">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative mt-1">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <i className='fa fa-search w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden="true"></i>
                    </div>
                    <input type="search"
                        id="table-search"
                        className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-48 sm:w-56 lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search for Notifications..."
                        value={searchQuery} // Bind value to searchQuery state
                        onChange={handleInputChange} // Call handleInputChange on input change
                    />
                </div>
            </div>

            <button
                onClick={toggleModal}
                data-modal-target="crud-modal"
                data-modal-toggle="crud-modal"
                type="button"
                className="focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                Add New
            </button>
        </div>

        <div className="relative overflow-y-auto md:w-full sm:w-screen shadow-md sm:rounded-lg">

            {
                isLoading ? <div className="loading">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#199A8E"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassNameclassName=""
                        visible={true}
                    />
                </div> : allNotifications.length === 0 ? <div className='flex justify-center align-middle p-12 text-teal-800'>
                    <h1 className='font-bold'>not found result try again</h1>
                </div> :
                    <table className="text-center w-full text-sm rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    #
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Header EN & AR
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Body EN & AR
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    isWatched By User
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    users
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    notification ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Updated By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Update
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Recycle BIN
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {allNotifications.map((notification, index) => <tr key={notification._id} className="text-center bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                                <th scope="row" className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {index + 1}
                                </th>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {notification.header.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {notification?.header?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {notification.body.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {notification?.body?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {notification.isWatched ? "Watched" : "NO"}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {notification?.user?.map((user, index) => <h6 key={index} className='mb-2'>{user.fullName?.split(' ')?.slice(0, 2)?.join(' ') || "not contain user"}</h6>)}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {notification._id}
                                </td>
                                <td className="px-3 py-4">
                                    {notification?.createdBy?.fullName.split(' ')?.slice(0, 2)?.join(' ') || "never yet Created"}
                                </td>
                                <td className="px-3 py-4">
                                    {notification?.updatedBy?.fullName.split(' ')?.slice(0, 2)?.join(' ') || "never yet updated"}
                                </td>
                                <td className="px-3 py-4 mx-auto">
                                    <button
                                        onClick={() => handleUpdateClick(notification)}
                                        data-modal-target="crud-modal-update"
                                        data-modal-toggle="crud-modal-update"
                                        type="button"
                                        className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:focus:ring-yellow-900">
                                        Update
                                    </button>
                                </td>
                                <td className="px-3 py-4 mx-auto">
                                    <button
                                        onClick={() => handleDeleteClick(notification)}
                                        data-modal-target="popup-modal"
                                        data-modal-toggle="popup-modal"
                                        type="button"
                                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                                        Delete
                                    </button>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
            }

        </div>



        {/* Main modal add */}
        <div
            id="crud-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-full max-w-md max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Create New Notification
                        </h3>
                        <button
                            onClick={toggleModal}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formik.handleSubmit} className="p-4 md:p-5">

                        <div className="grid gap-4 mb-4 grid-cols-2">

                            <div className="col-span-2">
                                <label htmlFor="en_header" className="block mb-2 text-sm font-medium text-gray-900 ">Notification Header English</label>
                                <textarea
                                    name="en_header"
                                    id="en_header"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.en_header}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type notification Header ex:notification Header in English..."
                                    rows="2"
                                ></textarea>

                                {formik.errors.en_header && formik.touched.en_header ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.en_header}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="ar_header" className="block mb-2 text-sm font-medium text-gray-900 ">Notification Header Arabic</label>
                                <textarea
                                    name="ar_header"
                                    id="ar_header"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ar_header}
                                    className="bg-gray-50 border text-right border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="...كتابة عنوان للاشعار بالغة العربية"
                                    rows="2"
                                ></textarea>

                                {formik.errors.ar_header && formik.touched.ar_header ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ar_header}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="en_body" className="block mb-2 text-sm font-medium text-gray-900 ">Notification Body English</label>
                                <textarea
                                    name="en_body"
                                    id="en_body"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.en_body}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type notification Body ex:notification Body in English..."
                                    rows="4"
                                ></textarea>

                                {formik.errors.en_body && formik.touched.en_body ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.en_body}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="ar_body" className="block mb-2 text-sm font-medium text-gray-900 ">Notification Body Arabic</label>
                                <textarea
                                    name="ar_body"
                                    id="ar_body"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ar_body}
                                    className="bg-gray-50 border text-right border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="...كتابة وصف للاشعار بالغة العربية"
                                    rows="4"
                                ></textarea>

                                {formik.errors.ar_body && formik.touched.ar_body ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ar_body}</div> : ''}
                            </div>

                            <div className="mb-4 col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900">users</label>
                                {users?.map(user1 => (
                                    <div key={user1._id} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`user1-${user1._id}`}
                                            name={`user1-${user1._id}`}
                                            value={user1._id}
                                            onChange={handleChangeUser}
                                            checked={selectedUsers.includes(user1._id)}
                                            className="text-primary-600 cursor-pointer rounded focus:ring-primary-600 h-4 w-4 mr-2"
                                        />
                                        <label htmlFor={`user1-${user1._id}`} className="text-gray-900 cursor-pointer">{user1.fullName?.split(' ')?.slice(0, 3)?.join(' ')}</label>
                                    </div>
                                ))}
                            </div>

                        </div>

                        <button type="submit" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                            Add new notification
                        </button>

                        {error ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{error}</div> : ''}
                    </form>
                </div>
            </div>
        </div>


        {/* Main modal update */}
        <div
            id="crud-modal-update"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-full max-w-md max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Update آotification
                        </h3>
                        <button
                            onClick={toggleModalUpdate}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formikUpdate.handleSubmit} className="p-4 md:p-5">

                        <div className="grid gap-4 mb-4 grid-cols-2">

                            <div className="col-span-2">
                                <label htmlFor="en_header" className="block mb-2 text-sm font-medium text-gray-900 ">Notification Header English</label>
                                <textarea
                                    name="en_header"
                                    id="en_header"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.en_header}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateNotificationData?.header?.en || "Type notification Header ex:notification Header in English..."}
                                    rows="2"
                                ></textarea>

                                {formikUpdate.errors.en_header && formikUpdate.touched.en_header ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.en_header}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="ar_header" className="block mb-2 text-sm font-medium text-gray-900 ">Notification Header Arabic</label>
                                <textarea
                                    name="ar_header"
                                    id="ar_header"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ar_header}
                                    className="bg-gray-50 border text-right border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateNotificationData?.header?.ar || "...كتابة عنوان للاشعار بالغة العربية"}
                                    rows="2"
                                ></textarea>

                                {formikUpdate.errors.ar_header && formikUpdate.touched.ar_header ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ar_header}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="en_body" className="block mb-2 text-sm font-medium text-gray-900 ">Notification Body English</label>
                                <textarea
                                    name="en_body"
                                    id="en_body"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.en_body}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateNotificationData?.body?.en || "Type notification Body ex:notification Body in English..."}
                                    rows="4"
                                ></textarea>

                                {formikUpdate.errors.en_body && formikUpdate.touched.en_body ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.en_body}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="ar_body" className="block mb-2 text-sm font-medium text-gray-900 ">Notification Body Arabic</label>
                                <textarea
                                    name="ar_body"
                                    id="ar_body"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ar_body}
                                    className="bg-gray-50 border text-right border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateNotificationData?.body?.ar || "...كتابة وصف للاشعار بالغة العربية"}
                                    rows="4"
                                ></textarea>

                                {formikUpdate.errors.ar_body && formikUpdate.touched.ar_body ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ar_body}</div> : ''}
                            </div>

                            <div className="mb-4 col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900">users</label>
                                {users?.map(user => (
                                    <div key={user._id} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`user-${user._id}`}
                                            name={`user-${user._id}`}
                                            value={user._id}
                                            onChange={handleChangeUser}
                                            checked={selectedUsers.includes(user._id)}
                                            className="text-primary-600 cursor-pointer rounded focus:ring-primary-600 h-4 w-4 mr-2"
                                        />
                                        <label htmlFor={`user-${user._id}`} className="text-gray-900 cursor-pointer">{user.fullName?.split(' ')?.slice(0, 3)?.join(' ')}</label>
                                    </div>
                                ))}
                            </div>

                        </div>

                        <button
                            // disabled={!(formikUpdate.isValid && formikUpdate.dirty)}
                            type='submit'
                            className="text-white inline-flex items-center bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
                        >

                            {/* <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg> */}
                            Update Notification
                        </button>

                        {errorUpdate ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{errorUpdate}</div> : ''}
                    </form>
                </div>
            </div>
        </div>



        {/* delete model */}
        <div
            id="popup-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal"
                        onClick={toggleModelDel}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg
                            className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                        </svg>
                        <h3 className="mb-6 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Move this Notification to the Recycle Bin ?
                        </h3>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                            onClick={submitDeleteNotification}
                        >
                            Yes, I'm sure
                        </button>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            onClick={toggleModelDel}
                            className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            No, cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </>
}
