import axios from 'axios';
import React, { useContext, useState } from 'react'
import { BallTriangle } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { UserContext } from '../../Context/UserContext.js';
import { Link } from 'react-router-dom';


export default function UserDetails() {

    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [error, setError] = useState(null)
    const [errorUpdate, setErrorUpdate] = useState(null)

    const [dropdownOpenButton, setDropdownOpenButton] = useState(false);
    const [dropdownOpenButton2, setDropdownOpenButton2] = useState(false);
    const toggleDropdownButton1 = () => {
        setDropdownOpenButton(!dropdownOpenButton);
    };

    const toggleDropdownButton2 = () => {
        setDropdownOpenButton2(!dropdownOpenButton2);
    };

    const handleInputChange = (event) => { // Function to handle input change
        setSearchQuery(event.target.value); // Update searchQuery state with input value
    };

    async function getAllUsers(searchQuery) {

        return await axios.get(`${process.env.REACT_APP_BACK_END}user?search=${searchQuery}`,
            {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                }
            })
    }

    let { isLoading, data, refetch } = useQuery(['AllUsersDetails', searchQuery], () => getAllUsers(searchQuery), {
        cacheTime: 9000000
    })


    let allUsers = []

    if (data?.data?.message == "success") {

        allUsers = data?.data?.users
    }

    // Sample country code data
    const countryCodes = [
        { code: '+20', countryCode: 'EG', country: 'Egypt' },
        { code: '+971', countryCode: 'AE', country: 'United Arab Emirates' },
        { code: '+1', countryCode: 'US', country: 'United States' },
        { code: '+7', countryCode: 'RU', country: 'Russia' },
        { code: '+33', countryCode: 'FR', country: 'France' },
        { code: '+44', countryCode: 'GB', country: 'United Kingdom' },
        { code: '+49', countryCode: 'DE', country: 'Germany' },
        { code: '+81', countryCode: 'JP', country: 'Japan' },
        { code: '+86', countryCode: 'CN', country: 'China' },
        { code: '+91', countryCode: 'IN', country: 'India' },
        { code: '+92', countryCode: 'PK', country: 'Pakistan' },
        { code: '+254', countryCode: 'KE', country: 'Kenya' },
        { code: '+355', countryCode: 'AL', country: 'Albania' },
        { code: '+213', countryCode: 'DZ', country: 'Algeria' },
        { code: '+376', countryCode: 'AD', country: 'Andorra' },
        { code: '+244', countryCode: 'AO', country: 'Angola' },
        { code: '+672', countryCode: 'AQ', country: 'Antarctica' },
        { code: '+54', countryCode: 'AR', country: 'Argentina' },
        { code: '+374', countryCode: 'AM', country: 'Armenia' },
        { code: '+297', countryCode: 'AW', country: 'Aruba' },
        { code: '+61', countryCode: 'AU', country: 'Australia' },
        { code: '+43', countryCode: 'AT', country: 'Austria' },
        { code: '+994', countryCode: 'AZ', country: 'Azerbaijan' },
        // Add more country codes as needed
    ];

    function getCountryInfo(countryCode = 'EG') {
        const country = countryCodes.find(country => country.countryCode === countryCode);

        if (country) {
            return `${country.code}`;
        } else {
            return 'Country code not found';
        }

    }


    return <>

        <div className="p-6 pe-8 overflow-y-auto rounded-t-xl bg-blue-gray-100 dark:bg-gray-900 flex justify-between align-middle">
            <div className="">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative mt-1">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <i className='fa fa-search w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden="true"></i>
                    </div>
                    <input type="search"
                        id="table-search"
                        className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-48 sm:w-56 lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search for users Details..."
                        value={searchQuery} // Bind value to searchQuery state
                        onChange={handleInputChange} // Call handleInputChange on input change
                    />
                </div>
            </div>

        </div>

        <div className="relative overflow-y-auto md:w-full sm:w-screen shadow-md sm:rounded-lg">

            {
                isLoading ? <div className="loading">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#199A8E"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassNameclassName=""
                        visible={true}
                    />
                </div> : allUsers.length === 0 ? <div className='flex justify-center align-middle p-12 text-teal-800'>
                    <h1 className='font-bold'>not found result try again</h1>
                </div> :
                    <table className="text-center overflow-y-auto w-full text-sm rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    #
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={toggleDropdownButton1}>
                                        Full Name
                                        <span className='ms-2'> {dropdownOpenButton ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Email || Phone Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Role
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    favorite
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    follow
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    joined
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    confirmAccount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Image
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    user ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Updated By
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {allUsers.map((user, index) => <tr key={user._id} className="text-center bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {index + 1}
                                </th>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.fullName}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.email ?
                                        <div className='flex  justify-between items-center'>
                                            <div>{user.email}</div>
                                            <Link to={`https://mail.google.com/mail/?view=cm&fs=1&to=${user.email}`} target='_blank'
                                                className="ms-2 flex justify-center items-center w-10 h-10 rounded-full text-white bg-[#8a8a8a] hover:bg-gray-500">
                                                <i className='fa-regular fa-envelope text-xl'></i>
                                            </Link>
                                        </div>
                                        :
                                        <div className='flex  justify-between items-center'>
                                            <div><span className='text-teal-500 me-1'>{user.countryCode}:</span> {user.phoneNumber}</div>
                                            <div className="flex justify-center items-center gap-x-2">
                                                {/* whats app */}
                                                <Link to={`https://wa.me/${getCountryInfo(user.countryCode) || '+20'}${user.phoneNumber}`} target='_blank'
                                                    className="flex justify-center items-center w-10 h-10 rounded-full text-white bg-green-500 hover:bg-green-600">
                                                    <i className='fa-brands fa-whatsapp text-xl'></i>
                                                </Link>
                                                {/* telegram*/}
                                                {/* <Link to={`https://t.me/share/url?url=https://wa.me/${getCountryInfo(user.countryCode) || '+20'}${user.phoneNumber}`} target='_blank' */}
                                                <Link to={`https://t.me/${getCountryInfo(user.countryCode) || '+20'}${user.phoneNumber}`} target='_blank'
                                                    className="flex justify-center items-center w-10 h-10 rounded-full text-white bg-[#26A5E4] hover:bg-teal-500">
                                                    <i className='fa-brands fa-telegram text-xl'></i>
                                                </Link>
                                            </div>
                                        </div>

                                    }
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.status}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.role}
                                </td>
                                <td className="px-6 text-left py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user?.favorite.length > 0 ? <h6>Coupon Code: <span className='text-teal-500 me-1'>{user?.favorite?.map((code, index) => <span key={index} className='border-e-2 border-gray-500 px-1'>{code.code}</span>)}</span></h6> : ""}

                                    {user?.favorite.map((favorite, index) => <div key={index}>
                                        <h6>category: <span className='text-teal-500 me-1'>{favorite?.category.map((category) => category.name.en)}</span></h6>
                                        <h6>brand: <span className='text-teal-500 me-1'>{favorite?.brand.map((brand) => brand.name.en)}</span></h6>
                                    </div>)}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user?.follow.length > 0 ? <h6>Brand Fav: <span className='text-teal-500 me-1'>{user?.follow?.map((follow, index) => <span key={index} className='border-e-2 border-gray-500 px-1'>{follow.name.en}</span>)}</span></h6> : ""}
                                </td>
                                <td className="px-6 py-4 text-teal-700 font-extrabold whitespace-nowrap dark:text-white">
                                    {new Date(user.joined)?.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric' // Change '2-digit' to 'numeric' for full year
                                    })}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.confirmAccount ? <span className='text-green-700 font-semibold'>
                                        Yes
                                        <i className="ms-1 fa fa-check-circle"></i>
                                    </span> : "No"}
                                </td>
                                <td className="px-6 py-4">
                                    <img src={user.image} className='w-14' alt={user.name} />
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user._id}
                                </td>
                                <td className="px-6 py-4">
                                    {user?.createdBy?.fullName?.split(' ')?.slice(0, 2)?.join(' ') || "never yet Created"}
                                </td>
                                <td className="px-6 py-4">
                                    {user?.updatedBy?.fullName?.split(' ')?.slice(0, 2)?.join(' ') || "never yet Updated"}
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
            }

        </div>

    </>
}
