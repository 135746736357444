import React from 'react'
import PieChart from '../PieChar/PieChar.jsx'
import Barchar from '../Barchar/Barchar.jsx'
import { Helmet } from 'react-helmet'
import AllCount from '../AllCount/AllCount.jsx'

export default function Home() {

    <Helmet>
        <meta name='description' content='Home Page' charSet="utf-8" />
        <title>Couponesta Dashboard</title>
    </Helmet>

    return <>

        {/* hear number of count and navigate in is want */}
        <div className="mb-16 w-full">
            <AllCount />
        </div>

        <div className="w-full h-96 md:h-96 flex flex-row justify-center align-middle flex-wrap md:flex-nowrap">


            <div className="col-span-6  w-full md:col-span-12 md:border-e-4 md:border-teal-500 md:border-dashed ">
                <PieChart />

            </div>

            <div className="col-span-6 w-full h-96  md:col-span-12 ps-1">
                <Barchar />
            </div>
        </div>


    </>
}
