import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BallTriangle } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default function Brand() {

    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [error, setError] = useState(null);
    const [errorUpdate, setErrorUpdate] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState(""); // State for selected filter

    const handleInputChange = (event) => { // Function to handle input change
        setSearchQuery(event.target.value); // Update searchQuery state with input value
    };

    const handleFilterChange = (filter) => { // Function to handle filter change
        if (selectedFilter === filter) {
            setSelectedFilter(""); // Clear filter if already selected
        } else {
            setSelectedFilter(filter); // Update selectedFilter state
        }
    };

    async function getBrands(searchQuery, selectedFilter) {
        let sortParam = "";
        if (selectedFilter === "Most used") {
            sortParam = "&sort=-mostUsed";
        } else if (selectedFilter === "Most followed") {
            sortParam = "&sort=-mostFollowed";
        } else if (selectedFilter === "alphabet A-Z") {
            sortParam = "&sort=name";
        } else if (selectedFilter === "alphabet Z-A") {
            sortParam = "&sort=-name";
        } else if (selectedFilter === "Recently added") {
            sortParam = "&sort=-createdAt";
        }

        return await axios.get(`${process.env.REACT_APP_BACK_END}brand/toDashboard?search=${searchQuery}${sortParam}`);
    }

    let { isLoading, data, refetch } = useQuery(
        ['Brands', searchQuery, selectedFilter],
        () => getBrands(searchQuery, selectedFilter),
        { cacheTime: 9000000 }
    );

    let allBrands = [];

    if (data?.data?.message === "success") {
        allBrands = data?.data?.brand;
    }



    // modal
    const toggleModal = () => {
        const modal = document.getElementById('crud-modal');
        modal.classList.toggle('hidden');
        modal.setAttribute('aria-hidden', modal.classList.contains('hidden'));
    };

    const toggleModalUpdate = () => {
        const modalUpdate = document?.getElementById('crud-modal-update');
        modalUpdate?.classList?.toggle('hidden');
        modalUpdate?.setAttribute('aria-hidden', modalUpdate?.classList?.contains('hidden'));
    };

    const toggleModelDel = () => {
        const modalDelete = document.getElementById('popup-modal');
        modalDelete.classList.toggle('hidden');
        modalDelete.setAttribute('aria-hidden', modalDelete.classList.contains('hidden'));
    }

    const toggleModelImg = () => {
        const modalImage = document.getElementById('popup-modal-image');
        modalImage.classList.toggle('hidden');
        modalImage.setAttribute('aria-hidden', modalImage.classList.contains('hidden'));
    }

    // Function to handle Image button click
    const [imageSrc, setImageSrc] = useState('');

    const handleImageClick = (img) => {
        setImageSrc(img); // Set the src of the Brand being image
        toggleModelImg(); // Show the img modal
    };


    // add brand

    const [image, setImage] = useState(null); // Use null instead of empty string for files
    function handelImage(e) {
        setImage(e.target.files[0])
    }

    const [locations, setLocations] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);

    useEffect(() => {
        async function fetchLocations() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACK_END}location`);
                setLocations(response?.data?.location);
            } catch (error) {
            }
        }

        fetchLocations();
    }, []);

    const handleChangeLocation = (event) => {
        const locationId = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            setSelectedLocations([...selectedLocations, locationId]);
        } else {
            setSelectedLocations(selectedLocations.filter(id => id !== locationId));
        }
    };

    // category
    const [category, setCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);

    useEffect(() => {
        async function fetchCategory() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACK_END}category/toDashboard`);
                setCategory(response?.data?.category);
            } catch (error) {
            }
        }

        fetchCategory();
    }, []);

    const handleChangeCategory = (event) => {
        const categoryId = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            setSelectedCategory([...selectedCategory, categoryId]);
        } else {
            setSelectedCategory(selectedCategory.filter(id => id !== categoryId));
        }
    };


    async function submitAddBrand(values) {
        const formData = new FormData();
        formData.append('image', image);
        formData.append('en_name', values.en_name);
        formData.append('ar_name', values.ar_name);
        formData.append('en_description', values.en_description);
        formData.append('ar_description', values.ar_description);
        formData.append('link', values.link);

        // Append selected location IDs
        selectedLocations.forEach(id => formData.append('location', id));

        // Append selected location IDs
        selectedCategory.forEach(id => formData.append('category', id));

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACK_END}brand`,
                formData,
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );


            await refetch();
            formik.resetForm();
            toggleModal()
            setSelectedLocations([]); // Reset selected locations
            setSelectedCategory([]); // Reset selected Category
            toast.success('Brand added successfully!');
        } catch (error) {
            setError(error?.response?.data?.message)
        }
    }

    let validateSchemaAdd = Yup.object({
        en_name: Yup.string().min(3, 'Category name is too short').max(30, 'Category name is too long').required('Category name is required'),
        ar_name: Yup.string().min(3, 'Category name is too short').max(30, 'Category name is too long').required('Category name is required'),
        en_description: Yup.string().min(3, 'Category description is too short').max(255, 'Category description is too long').required('Category description is required'),
        ar_description: Yup.string().min(3, 'Category description is too short').max(255, 'Category description is too long').required('Category description is required'),
        link: Yup.string().url('Invalid Link').required('Link is required'),
        image: Yup.mixed().required('Image is required'), // Uncomment if image is required

    });

    let formik = useFormik({
        initialValues: {
            en_name: '',
            ar_name: '',
            en_description: '',
            ar_description: '',
            link: '',
            // image: null, // Uncomment if image is required
        },
        validationSchema: validateSchemaAdd,
        onSubmit: submitAddBrand
    });

    // end add Brand


    // Update Brand
    const [imageUpdate, setImageUpdate] = useState(null); // Use null instead of empty string for files
    function handelImageUpdate(e) {
        setImageUpdate(e.target.files[0])
    }

    const [updateBrandData, setUpdateBrandData] = useState(null);
    const [updatingBrandId, setUpdatingBrandId] = useState(null);

    // Function to handle update button click
    const handleUpdateClick = (brand) => {
        setUpdateBrandData(brand); // Set the Brand data to state
        setUpdatingBrandId(brand?._id); // Set the ID of the Brand being updated
        toggleModalUpdate(); // Show the update modal
    };


    // Function to submit update location
    async function submitUpdateBrand(values) {

        const formData = new FormData();

        if (imageUpdate === "" || imageUpdate === null) {
            // If image is an empty string, don't append anything
        } else {
            // If image is not an empty string, append it to formData
            formData.append('image', imageUpdate);
        }

        formData.append('en_name', values.en_name || ""); // If empty, take value from updateCategoryData
        formData.append('ar_name', values.ar_name || ""); // If empty, take value from updateCategoryData
        formData.append('en_description', values.en_description || ""); // If empty, take value from updateCategoryData
        formData.append('ar_description', values.ar_description || ""); // If empty, take value from updateCategoryData
        formData.append('link', values.link || updateBrandData.link || ""); // If empty, take value from updateCategoryData

        // Append selected location IDs
        selectedLocations.forEach(id => formData.append('location', id))

        // Append selected Category IDs
        selectedCategory.forEach(id => formData.append('category', id))

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACK_END}brand/${updatingBrandId}`,
                formData,
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();
            formikUpdate.resetForm();
            toggleModalUpdate()
            setImageUpdate(null)
            setSelectedLocations([]); // Reset selected locations
            setSelectedCategory([]); // Reset selected categories
            toast.success('brand updated successfully!');
        } catch (error) {

            setErrorUpdate(error?.response?.data?.message)
        }
    }

    let validateSchemaUpdate = Yup.object({
        en_name: Yup.string().min(3, 'Category name is too short').max(30, 'Category name is too long'),
        ar_name: Yup.string().min(3, 'Category name is too short').max(30, 'Category name is too long'),
        en_description: Yup.string().min(3, 'Category description is too short').max(255, 'Category description is too long'),
        ar_description: Yup.string().min(3, 'Category description is too short').max(255, 'Category description is too long'),
        link: Yup.string().url('Invalid Link'),
        image: Yup.mixed(), // Uncomment if image is required

    })

    let formikUpdate = useFormik({
        initialValues: {
            en_name: '',
            ar_name: '',
            en_description: '',
            ar_description: '',
            link: '',
            // image: null, // Uncomment if image is required

        }, validationSchema: validateSchemaUpdate,
        onSubmit: submitUpdateBrand

    })

    // end update brand


    // delete brand
    const [deleteBrandId, setDeleteBrandId] = useState(null);

    // Function to handle update button click
    const handleDeleteClick = (brand) => {
        setDeleteBrandId(brand?._id); // Set the ID of the Brand being updated
        toggleModelDel(); // Show the update modal
    };

    // Function to submit update Brand
    async function submitDeleteBrand() {

        const formData = new FormData();
        formData.append('isDeleted', 'true');

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACK_END}brand/${deleteBrandId}`,
                formData,
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();
            toggleModelDel()
            toast.success('Brand Moved to Recycle bin successfully!');
        } catch (error) {
            setError(error?.response?.data?.message)
        }
    }


    return <>


        <Helmet>
            <meta name='description' content='Brands Page' charSet="utf-8" />
            <title>Brands</title>
        </Helmet>


        <div className="p-6 pe-8 overflow-y-auto rounded-t-xl bg-blue-gray-100 dark:bg-gray-900 flex justify-between align-middle">
            <div className="">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative mt-1">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <i className='fa fa-search w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden="true"></i>
                    </div>
                    <input type="search"
                        id="table-search"
                        className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-48 sm:w-56 lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search for Brands..."
                        value={searchQuery} // Bind value to searchQuery state
                        onChange={handleInputChange} // Call handleInputChange on input change
                    />
                </div>
            </div>

            <button
                onClick={toggleModal}
                data-modal-target="crud-modal"
                data-modal-toggle="crud-modal"
                type="button"
                className="focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                Add New
            </button>
        </div>

        <div className="relative overflow-y-auto md:w-full sm:w-screen shadow-md sm:rounded-lg">

            {
                isLoading ? <div className="loading">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#199A8E"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassNameclassName=""
                        visible={true}
                    />
                </div> : allBrands?.length === 0 ? <div className='flex justify-center align-middle p-12 text-teal-800'>
                    <h1 className='font-bold'>not found result try again</h1>
                </div> :
                    <table className="text-center w-full text-sm rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    #
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={() => handleFilterChange("alphabet A-Z")}>
                                        Name EN & AR
                                        <span className='ms-2'> {selectedFilter === "alphabet A-Z" ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={() => handleFilterChange("Most used")}>
                                        Most Used
                                        <span className='ms-2'> {selectedFilter === "Most used" ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={() => handleFilterChange("Most followed")}>
                                        Most Followed
                                        <span className='ms-2'> {selectedFilter === "Most followed" ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Description EN & AR
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Locations
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Categories
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Image
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Link
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Brand ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Updated By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Update
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Recycle BIN
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {allBrands?.map((brand, index) => <tr key={brand._id} className="text-center bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                                <th scope="row" className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {index + 1}
                                </th>
                                <td className="px-3 py-4 flex-col justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {brand.name.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {brand.name.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {brand.mostUsed}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {brand.mostFollowed}
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {brand.description.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {brand?.description?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {brand?.location?.map((location, index) => <h6 key={location._id} className='mb-2  pb-1 border-b-2 border-gray-200'>{location.name}</h6>)}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {brand?.category?.map((category, index) => <div key={index}>
                                        <h6 className='mb-2 pb-1 border-b-2 border-gray-200'>
                                            {category?.name.en}
                                        </h6>
                                    </div>)}
                                </td>
                                <td className="px-3 py-4">
                                    <img
                                        onClick={() => handleImageClick(brand?.image)}
                                        data-modal-target="popup-modal-image"
                                        data-modal-toggle="popup-modal-image"
                                        src={brand?.image} className='w-14 cursor-pointer' alt={brand.name.en} />
                                </td>
                                <td className="px-3 py-4 font-medium whitespace-nowrap dark:text-white">
                                    <Link to={brand.link} target='_blank' className="text-blue-500 hover:text-blue-700">
                                        {brand.link}
                                    </Link>
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {brand._id}
                                </td>
                                <td className="px-3 py-4">
                                    {brand?.createdBy?.fullName.split(' ')?.slice(0, 2)?.join(' ') || "never yet Created"}
                                </td>
                                <td className="px-3 py-4">
                                    {brand?.updatedBy?.fullName.split(' ')?.slice(0, 2)?.join(' ') || "never yet updated"}
                                </td>
                                <td className="px-3 py-4 mx-auto">
                                    <button
                                        onClick={() => handleUpdateClick(brand)}
                                        data-modal-target="crud-modal-update"
                                        data-modal-toggle="crud-modal-update"
                                        type="button"
                                        className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:focus:ring-yellow-900">
                                        Update
                                    </button>
                                </td>
                                <td className="px-3 py-4 mx-auto">
                                    <button
                                        onClick={() => handleDeleteClick(brand)}
                                        data-modal-target="popup-modal"
                                        data-modal-toggle="popup-modal"
                                        type="button"
                                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                                        Delete
                                    </button>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
            }

        </div>



        {/* Main modal add */}
        <div
            id="crud-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-full max-w-md max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Create New Brand
                        </h3>
                        <button
                            onClick={toggleModal}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formik.handleSubmit} className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label htmlFor="en_name" className="block mb-2 text-sm font-medium text-gray-900 ">Brand Name English <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="en_name"
                                    id="en_name"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.en_name}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Brand name ex:noon..."
                                />

                                {formik.errors.en_name && formik.touched.en_name ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.en_name}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="ar_name" className="block mb-2 text-sm font-medium text-gray-900 ">Brand Name Arabic <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="ar_name"
                                    id="ar_name"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ar_name}
                                    className="bg-gray-50 border text-right border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="...كتابة اسم المتجر بالغة العربية مثل: نون"
                                />

                                {formik.errors.ar_name && formik.touched.ar_name ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ar_name}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="en_description" className="block mb-2 text-sm font-medium text-gray-900 ">Brand Description English <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="en_description"
                                    id="en_description"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.en_description}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Brand Description ex:Brand Description in English..."
                                    rows="4"
                                ></textarea>

                                {formik.errors.en_description && formik.touched.en_description ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.en_description}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="ar_description" className="block mb-2 text-sm font-medium text-gray-900 ">Brand Description Arabic <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="ar_description"
                                    id="ar_description"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ar_description}
                                    className="bg-gray-50 border text-right border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="...كتابة وصف للمتجر بالغة العربية"
                                    rows="4"
                                ></textarea>

                                {formik.errors.ar_description && formik.touched.ar_description ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ar_description}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="link" className="block mb-2 text-sm font-medium text-gray-900 ">Brand Link</label>
                                <input
                                    type="text"
                                    name="link"
                                    id="link"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.link}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type a Link hear... ex: http://www.example.com"
                                />

                                {formik.errors.link && formik.touched.link ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.link}</div> : ''}
                            </div>

                            <div className="mb-4 border-r-2 pe-1 border-blue-gray-200">
                                <label className="block mb-2 text-sm font-medium text-gray-900">Locations <span className='text-red-600 font-extrabold '>*</span></label>
                                {locations.map(location => (
                                    <div key={location._id} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`location-${location._id}`}
                                            name={`location-${location._id}`}
                                            value={location._id}
                                            onChange={handleChangeLocation}
                                            checked={selectedLocations.includes(location._id)}
                                            className="text-primary-600 cursor-pointer rounded focus:ring-primary-600 h-4 w-4 mr-2"
                                        />
                                        <label htmlFor={`location-${location._id}`} className="text-gray-900">{location.name}</label>
                                    </div>
                                ))}
                            </div>

                            <div className="mb-4">
                                <label className="block mb-2 text-sm font-medium text-gray-900">Categories <span className='text-red-600 font-extrabold '>*</span></label>
                                {category?.map(category => (
                                    <div key={category._id} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`category-${category._id}`}
                                            name={`category-${category._id}`}
                                            value={category._id}
                                            onChange={handleChangeCategory}
                                            checked={selectedCategory.includes(category._id)}
                                            className="text-primary-600 cursor-pointer rounded focus:ring-primary-600 h-4 w-4 mr-2"
                                        />
                                        <label htmlFor={`category-${category._id}`} className="text-gray-900">{category?.name?.en}</label>
                                    </div>
                                ))}
                            </div>


                            {/* image */}
                            <div className="col-span-2">
                                <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900">Image <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    id="image"
                                    name="image"
                                    type="file"
                                    onChange={(e) => {
                                        handelImage(e);
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                />
                                {formik.errors.image && formik.touched.image && (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.image}</div>
                                )}
                            </div>

                        </div>

                        <button type="submit" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                            Add new Brand
                        </button>

                        {error ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{error}</div> : ''}
                    </form>
                </div>
            </div>
        </div>


        {/* Main modal update */}
        <div
            id="crud-modal-update"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-full max-w-md max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Update Category
                        </h3>
                        <button
                            onClick={toggleModalUpdate}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formikUpdate.handleSubmit} className="p-4 md:p-5">

                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label htmlFor="en_name" className="block mb-2 text-sm font-medium text-gray-900 ">Brand Name English</label>
                                <input
                                    type="text"
                                    name="en_name"
                                    id="en_name"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.en_name}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateBrandData?.name?.en || "Type Brand name ex:noon..."}
                                />

                                {formikUpdate.errors.en_name && formikUpdate.touched.en_name ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.en_name}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="ar_name" className="block mb-2 text-sm font-medium text-gray-900 ">Brand Name Arabic</label>
                                <input
                                    type="text"
                                    name="ar_name"
                                    id="ar_name"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ar_name}
                                    className="bg-gray-50 border text-right border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateBrandData?.name?.ar || "...كتابة اسم المتجر بالغة العربية مثل: نون"}
                                />

                                {formikUpdate.errors.ar_name && formikUpdate.touched.ar_name ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ar_name}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="en_description" className="block mb-2 text-sm font-medium text-gray-900 ">Brand Description English</label>
                                <textarea
                                    name="en_description"
                                    id="en_description"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.en_description}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateBrandData?.description?.en || "Type Brand Description ex:Brand Description in English..."}
                                    rows="4"
                                ></textarea>

                                {formikUpdate.errors.en_description && formikUpdate.touched.en_description ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.en_description}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="ar_description" className="block mb-2 text-sm font-medium text-gray-900 ">Brand Description Arabic</label>
                                <textarea
                                    name="ar_description"
                                    id="ar_description"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ar_description}
                                    className="bg-gray-50 border text-right border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateBrandData?.description?.ar || "...كتابة وصف للمتجر بالغة العربية"}
                                    rows="4"
                                ></textarea>

                                {formikUpdate.errors.ar_description && formikUpdate.touched.ar_description ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ar_description}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="link" className="block mb-2 text-sm font-medium text-gray-900 ">Brand Name Arabic</label>
                                <input
                                    type="text"
                                    name="link"
                                    id="link"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.link}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateBrandData?.link || "Type a Link hear... ex: http://www.example.com"}
                                />

                                {formikUpdate.errors.link && formikUpdate.touched.link ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.link}</div> : ''}
                            </div>

                            <div className="mb-4 border-r-2 pe-1 border-blue-gray-200">
                                <label className="block mb-2 text-sm font-medium text-gray-900">Locations</label>
                                {locations.map(location => (
                                    <div key={location._id} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`location-${location._id}`}
                                            name={`location-${location._id}`}
                                            value={location._id}
                                            onChange={handleChangeLocation}
                                            checked={selectedLocations.includes(location._id)}
                                            className="text-primary-600 cursor-pointer rounded focus:ring-primary-600 h-4 w-4 mr-2"
                                        />
                                        <label htmlFor={`location-${location._id}`} className="text-gray-900">{location.name}</label>
                                    </div>
                                ))}
                            </div>

                            <div className="mb-4">
                                <label className="block mb-2 text-sm font-medium text-gray-900">Categories</label>
                                {category?.map(category => (
                                    <div key={category._id} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`category-${category._id}`}
                                            name={`category-${category._id}`}
                                            value={category._id}
                                            onChange={handleChangeCategory}
                                            checked={selectedCategory.includes(category._id)}
                                            className="text-primary-600 cursor-pointer rounded focus:ring-primary-600 h-4 w-4 mr-2"
                                        />
                                        <label htmlFor={`category-${category._id}`} className="text-gray-900">{category?.name?.en}</label>
                                    </div>
                                ))}
                            </div>


                            {/* image */}
                            <div className="col-span-2">
                                <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900">Image</label>
                                <input
                                    id="image"
                                    name="image"
                                    type="file"
                                    onChange={(e) => {
                                        handelImageUpdate(e);
                                        formikUpdate.handleChange(e);
                                    }}
                                    onBlur={formikUpdate.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                />
                                {formikUpdate.errors.image && formikUpdate.touched.image && (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.image}</div>
                                )}
                            </div>

                        </div>

                        <button
                            // disabled={!(formikUpdate.isValid && formikUpdate.dirty)}
                            type='submit'
                            className="text-white inline-flex items-center bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
                        >

                            {/* <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg> */}
                            Update Brand
                        </button>

                        {errorUpdate ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{errorUpdate}</div> : ''}
                    </form>
                </div>
            </div>
        </div>



        {/* delete model */}
        <div
            id="popup-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal"
                        onClick={toggleModelDel}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg
                            className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                        </svg>
                        <h3 className="mb-6 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Move this Brand to the Recycle Bin ?
                        </h3>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                            onClick={submitDeleteBrand}
                        >
                            Yes, I'm sure
                        </button>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            onClick={toggleModelDel}
                            className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            No, cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>



        {/* image model */}
        <div
            id="popup-modal-image"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-6 w-full max-w-md max-h-full">
                <div className="relative bg-[#ffffffd1] rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal-image"
                        onClick={toggleModelImg}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>

                    <div className="p-1 md:p-2 text-center">

                        <img src={imageSrc || ""} alt="" className='w-full h-full p-3 py-14' />



                        <button
                            data-modal-hide="popup-modal-image"
                            type="button"
                            onClick={toggleModelImg}
                            className="absolute bottom-0.5 right-2 py-2 px-4 ms-3 text-md font-medium text-white focus:outline-none bg-teal-500 hover:bg-teal-700 focus:bg-teal-700 rounded-xl border border-gray-200 focus:z-10 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            close
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </>
}
