import React, { useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import LoginWithEmail from '../LoginWithEmail/LoginWithEmail.jsx'
import logo from '../../Assets/images/couponesta.jpg'
import { Helmet } from 'react-helmet';

export default function Login() {

    const location = useLocation();

    const isActiveEmail = location.pathname === '/login';
    const isActivePhone = location.pathname === '/login/loginWithPhone';


    return <>

        <Helmet>
            <meta name='description' content='Login Page' charSet="utf-8" />
            <title>Login</title>
        </Helmet>

        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">

            <div className="sm:mx-auto sm:w-full sm:max-w-sm">

                <img
                    className="mx-auto h-20 rounded-xl  w-auto"
                    src={logo}
                    alt="Your Company"
                />

                <h2 className="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Sign in to your account
                </h2>

                <div className="flex justify-center align-middle gap-x-4 mt-5 font-semibold">

                    <Link to={'/login'}>
                        <button
                            className={`border-2 rounded-xl px-4 py-3 border-gray-500 ${isActiveEmail ? 'bg-[#3b35b4] border-[#3b35b4] text-white' : ''}`}
                        >
                            With Email
                        </button>
                    </Link>

                    <Link to={'/login/loginWithPhone'}>
                        <button
                            className={`border-2 rounded-xl px-4 py-3 border-gray-500 ${isActivePhone ? 'bg-[#3b35b4] border-[#3b35b4] text-white' : ''}`}
                        >
                            With Phone
                        </button>
                    </Link>

                </div>
            </div>


            <Outlet>
                <LoginWithEmail />
            </Outlet>

        </div>
    </>
}
