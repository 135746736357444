import { RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import UserContextProvider from './Context/UserContext.js';
import Layout from './components/Layout/Layout.jsx';
import Login from './components/Login/Login.jsx';
import LoginWithEmail from './components/LoginWithEmail/LoginWithEmail.jsx';
import LoginWithPhone from './components/LoginWithPhone/LoginWithPhone.jsx';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute.jsx';
import Home from './components/Home/Home.jsx';
import Location from './components/Location/Location.jsx';
import Notfound from './components/Notfound/Notfound.jsx';
import Category from './components/Category/Category.jsx';
import Brand from './components/Brands/Brand.jsx';
import LocationDeleted from './components/Location/LocationDeleted.jsx';
import CategoryDeleted from './components/Category/CategoryDeleted.jsx';
import BrandDeleted from './components/Brands/BrandDeleted.jsx';
import Ads from './components/Ads/Ads.jsx';
import AdsDeleted from './components/Ads/AdsDeleted.jsx';
import Coupon from './components/Coupon/Coupon.jsx';
import CouponDeleted from './components/Coupon/CouponDeleted.jsx';
import Notification from './components/Notification/Notification.jsx';
import NotificationDeleted from './components/Notification/NotificationDeleted.jsx';
import User from './components/User/User.jsx';
import UserDetails from './components/User/UserDetails.jsx';
import UserDeleted from './components/User/UserDeleted.jsx';
import Barchar from './components/Barchar/Barchar.jsx';
import PieChar from './components/PieChar/PieChar.jsx';
import FavoriteBrand from './components/Brands/FavoriteBrand.jsx';
import NewPassword from './components/NewPassword/NewPassword.jsx';
import ForgetPassword from './components/ForgetPassword/ForgetPassword.jsx';
import ForgetPasswordEmail from './components/ForgetPasswordEmail/ForgetPasswordEmail.jsx';
import ForgetPasswordPhone from './components/ForgetPasswordPhone/ForgetPasswordPhone.jsx';
import ConfirmationEmail from './components/ConfirmationEmail/ConfirmationEmail.jsx';

let routers = createHashRouter([

  {
    path: 'login', element: <Login />, children: [
      { index: true, element: <LoginWithEmail /> },
      { path: 'loginWithPhone', element: <LoginWithPhone /> },
    ],

  },


  { path: 'newPassword/:email', element: <NewPassword /> },

  {
    path: 'forgetPassword', element: <ForgetPassword />, children: [
      { index: true, element: <ForgetPasswordEmail /> },
      { path: 'withPhone', element: <ForgetPasswordPhone /> },
    ],

  },

  { path: 'confirmationEmail/:email', element: <ConfirmationEmail /> },



  {
    path: '/', element: <ProtectedRoute> <Layout /> </ProtectedRoute>, children: [
      { index: true, element: <ProtectedRoute> <Home /> </ProtectedRoute> },
      // { path: 'freshCart', element: <ProtectedRoute> <Home /> </ProtectedRoute> },
      { path: 'location', element: <ProtectedRoute> <Location /> </ProtectedRoute> },
      { path: 'locationdeleted', element: <ProtectedRoute> <LocationDeleted /> </ProtectedRoute> },

      { path: 'category', element: <ProtectedRoute> <Category /> </ProtectedRoute> },
      { path: 'categorydeleted', element: <ProtectedRoute> <CategoryDeleted /> </ProtectedRoute> },

      { path: 'brand', element: <ProtectedRoute> <Brand /> </ProtectedRoute> },
      { path: 'favoritebrand', element: <ProtectedRoute> <FavoriteBrand /> </ProtectedRoute> },
      { path: 'branddeleted', element: <ProtectedRoute> <BrandDeleted /> </ProtectedRoute> },

      { path: 'ads', element: <ProtectedRoute> <Ads /> </ProtectedRoute> },
      { path: 'adsdeleted', element: <ProtectedRoute> <AdsDeleted /> </ProtectedRoute> },

      { path: 'coupon', element: <ProtectedRoute> <Coupon /> </ProtectedRoute> },
      { path: 'coupondeleted', element: <ProtectedRoute> <CouponDeleted /> </ProtectedRoute> },

      { path: 'notification', element: <ProtectedRoute> <Notification /> </ProtectedRoute> },
      { path: 'notificationdeleted', element: <ProtectedRoute> <NotificationDeleted /> </ProtectedRoute> },

      { path: 'user', element: <ProtectedRoute> <User /> </ProtectedRoute> },
      { path: 'userdetails', element: <ProtectedRoute> <UserDetails /> </ProtectedRoute> },
      { path: 'userdeleted', element: <ProtectedRoute> <UserDeleted /> </ProtectedRoute> },

      { path: 'barchar', element: <ProtectedRoute> <Barchar /> </ProtectedRoute> },

      { path: 'piechar', element: <ProtectedRoute> <PieChar /> </ProtectedRoute> },

      { path: '*', element: <Notfound /> },
    ]
  }
])


function App() {


  return <UserContextProvider>

    <RouterProvider router={routers}></RouterProvider>

  </UserContextProvider>

}

export default App;
