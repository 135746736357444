import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { BallTriangle } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';


export default function User() {

    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [error, setError] = useState(null);
    const [errorUpdate, setErrorUpdate] = useState(null);
    const [sortName, setSortName] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);

    const ToggleSortName = () => {
        setSortName(!sortName);
    };

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    async function getAllUsers() {
        return await axios.get(`${process.env.REACT_APP_BACK_END}user`, {
            headers: {
                authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
            }
        });
    }

    const { isLoading, data, refetch } = useQuery(['AllUsers'], () => getAllUsers(), {
        cacheTime: 9000000
    });

    let allUsers = [];

    if (data?.data?.message === "success") {
        allUsers = data?.data?.users;
    }

    useEffect(() => {
        if (allUsers.length > 0) {
            let filtered = allUsers.filter(user =>
                user?.fullName?.toLowerCase().includes(searchQuery.toLowerCase())
            );

            if (sortName) {
                filtered = filtered.sort((a, b) => {
                    if (a.fullName < b.fullName) return -1;
                    if (a.fullName > b.fullName) return 1;
                    return 0;
                });
            }

            setFilteredUsers(filtered);
        }
    }, [data, sortName, searchQuery]);

    const highlightText = (text, highlight) => {
        if (!highlight.trim()) {
            return text;
        }
        const regex = new RegExp(`(${highlight})`, 'gi');
        const parts = text.split(regex);
        return parts.map((part, i) =>
            regex.test(part) ? <span key={i} style={{ color: 'red' }}>{part}</span> : part
        );
    };

    // modal
    const toggleModal = () => {
        const modal = document.getElementById('crud-modal');
        modal.classList.toggle('hidden');
        modal.setAttribute('aria-hidden', modal.classList.contains('hidden'));
    };

    const toggleModalUpdate = () => {
        const modalUpdate = document?.getElementById('crud-modal-update');
        modalUpdate?.classList?.toggle('hidden');
        modalUpdate?.setAttribute('aria-hidden', modalUpdate?.classList?.contains('hidden'));
    };

    const toggleModelDel = () => {
        const modalDelete = document.getElementById('popup-modal');
        modalDelete.classList.toggle('hidden');
        modalDelete.setAttribute('aria-hidden', modalDelete.classList.contains('hidden'));
    }

    const toggleModelImg = () => {
        const modalImage = document.getElementById('popup-modal-image');
        modalImage.classList.toggle('hidden');
        modalImage.setAttribute('aria-hidden', modalImage.classList.contains('hidden'));
    }

    // Function to handle Image button click
    const [imageSrc, setImageSrc] = useState('');

    const handleImageClick = (img) => {
        setImageSrc(img); // Set the src of the Brand being image
        toggleModelImg(); // Show the img modal
    };


    // add user
    const [image, setImage] = useState(null); // Use null instead of empty string for files
    function handelImage(e) {
        setImage(e.target.files[0])
    }

    // Function to submit add user
    async function submitAddUser(values) {

        try {

            const formData = new FormData();
            formData.append('image', image);
            formData.append('fullName', values.fullName);
            formData.append('email', values.email);
            formData.append('phoneNumber', values.phoneNumber);
            formData.append('countryCode', values.countryCode);
            formData.append('password', values.password);
            formData.append('role', values.role);

            const response = await axios.post(
                `${process.env.REACT_APP_BACK_END}user`,
                formData,
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                        'Content-Type': 'multipart/form-data', // Set content type for FormData
                    },
                }
            );

            // console.log(response.data);

            await refetch();
            formik.resetForm();
            toggleModal()
            setImage(null)
            toast.success('user added successfully!');

        } catch (error) {
            // console.log('Error:', error.response.data);
            // toast.error(error?.response?.data?.message);
            setError(error?.response?.data?.message)
        }
    }

    let validateSchemaAdd = Yup.object({
        fullName: Yup.string().min(1).max(100).required(),
        email: Yup.string()
            .email('Invalid email format'),
        password: Yup.string().min(6, 'Password must with character and minLength 6').required('password is required'),
        role: Yup.string().oneOf(['User', 'Admin', 'Employee']).default('User'),
        phoneNumber: Yup.string(),
        countryCode: Yup.string(),
        image: Yup.mixed(),
    })

    let formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            role: 'User',
            fullName: '',
            password: '',
            phoneNumber: '',
            countryCode: '',
            image: null, // Use null for file initial value

        }, validationSchema: validateSchemaAdd,
        onSubmit: submitAddUser

    })

    // end add user


    // update user

    const [imageUpdate, setImageUpdate] = useState(null); // Use null instead of empty string for files
    function handelImageUpdate(e) {
        setImageUpdate(e.target.files[0])
    }

    const [updateUserData, setUpdateUserData] = useState(null);
    const [updatingUserId, setUpdatingUserId] = useState(null);

    // Function to handle update button click
    const handleUpdateClick = (user) => {
        setUpdateUserData(user); // Set the user data to state
        setUpdatingUserId(user?._id); // Set the ID of the user being updated
        toggleModalUpdate(); // Show the update modal
    };


    // Function to submit update user
    async function submitUpdateUser(values) {

        try {


            const formData = new FormData();
            // formData.append('image', image ? image : (updateUserData.image ? updateUserData.image : ''));
            // formData.append('fullName', values.fullName || updateUserData.fullName);
            // formData.append('email', values.email || updateUserData.email);
            // formData.append('phoneNumber', values.phoneNumber || updateUserData.phoneNumber);
            // formData.append('countryCode', values.countryCode || updateUserData.countryCode);
            // formData.append('password', values.password);
            // formData.append('role', values.role || updateUserData.role);

            if (imageUpdate === "" || imageUpdate === null) {
                // If image is an empty string, don't append anything
            } else {
                // If image is not an empty string, append it to formData
                formData.append('image', imageUpdate);
            }

            if (values.fullName === "") {
                // If values.fullName is an empty string, don't append anything
            } else {
                // If values.fullName is not an empty string, append it to formData
                formData.append('fullName', values.fullName || updateUserData.fullName);
            }

            if (values.email === "") {
                // If values.email is an empty string, don't append anything
            } else {
                // If values.email is not an empty string, append it to formData
                formData.append('email', values.email || updateUserData.email);
            }

            if (values.phoneNumber === "") {
                // If values.phoneNumber is an empty string, don't append anything
            } else {
                // If values.phoneNumber is not an empty string, append it to formData
                formData.append('phoneNumber', values.phoneNumber || updateUserData.phoneNumber);
            }

            if (values.countryCode === "") {
                // If values.countryCode is an empty string, don't append anything
            } else {
                // If values.countryCode is not an empty string, append it to formData
                formData.append('countryCode', values.countryCode || updateUserData.countryCode);
            }

            if (values.password === "") {
                // If values.password is an empty string, don't append anything
            } else {
                // If values.password is not an empty string, append it to formData
                formData.append('password', values.password);
            }

            if (values.role === "") {
                // If values.role is an empty string, don't append anything
            } else {
                // If values.role is not an empty string, append it to formData
                formData.append('role', values.role || updateUserData.role);
            }

            if (values.confirmAccount === "") {
                // If values.confirmAccount is an empty string, don't append anything
            } else {
                // If values.confirmAccount is not an empty string, append it to formData
                formData.append('confirmAccount', values.confirmAccount || updateUserData.confirmAccount);
            }

            // console.log(values);



            const response = await axios.put(
                `${process.env.REACT_APP_BACK_END}user/admin/${updatingUserId}`,
                formData,
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            // console.log(response.data);

            await refetch();
            formikUpdate.resetForm();
            toggleModalUpdate()
            setImageUpdate(null)
            toast.success('user updated successfully!');
        } catch (error) {
            // console.log('Error:', error.response.data);
            // toast.error(error?.response?.data?.message);
            setErrorUpdate(error?.response?.data?.message)
        }
    }

    let validateSchemaUpdate = Yup.object({
        fullName: Yup.string().min(1).max(100),
        email: Yup.string()
            .email('Invalid email format'),
        password: Yup.string().min(6, 'Password must with character and minLength 6'),
        role: Yup.string().oneOf(['User', 'Admin', 'Employee']).default('User'),
        phoneNumber: Yup.string(),
        countryCode: Yup.string(),
        confirmAccount: Yup.boolean(),
        // image: Yup.string(),
    })

    let formikUpdate = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            role: '',
            fullName: '',
            password: '',
            phoneNumber: '',
            countryCode: '',
            confirmAccount: false, // Initialize with false for boolean field
            image: null, // Use null for file initial value

        }, validationSchema: validateSchemaUpdate,
        onSubmit: submitUpdateUser

    })

    // end update user


    // delete user
    const [deleteUserId, setDeleteUserId] = useState(null);

    // Function to handle update button click
    const handleDeleteClick = (user) => {
        setDeleteUserId(user?._id); // Set the ID of the user being updated
        toggleModelDel(); // Show the update modal
    };

    // Function to submit update user
    async function submitDeleteUser() {

        const formData = new FormData();
        formData.append('isDeleted', 'true');

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACK_END}user/admin/${deleteUserId}`,
                formData,
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            // console.log(response.data);

            await refetch();
            toggleModelDel()
            toast.success('user delete successfully!');
        } catch (error) {
            // console.error('Error:', error?.response?.data);
            toast.error(error?.response?.data?.message);
            // setError(error?.response?.data?.message)
        }
    }

    // Sample country code data
    const countryCodes = [
        { code: '+20', countryCode: 'EG', country: 'Egypt' },
        { code: '+971', countryCode: 'AE', country: 'United Arab Emirates' },
        { code: '+1', countryCode: 'US', country: 'United States' },
        { code: '+7', countryCode: 'RU', country: 'Russia' },
        { code: '+33', countryCode: 'FR', country: 'France' },
        { code: '+44', countryCode: 'GB', country: 'United Kingdom' },
        { code: '+49', countryCode: 'DE', country: 'Germany' },
        { code: '+81', countryCode: 'JP', country: 'Japan' },
        { code: '+86', countryCode: 'CN', country: 'China' },
        { code: '+91', countryCode: 'IN', country: 'India' },
        { code: '+92', countryCode: 'PK', country: 'Pakistan' },
        { code: '+254', countryCode: 'KE', country: 'Kenya' },
        { code: '+355', countryCode: 'AL', country: 'Albania' },
        { code: '+213', countryCode: 'DZ', country: 'Algeria' },
        { code: '+376', countryCode: 'AD', country: 'Andorra' },
        { code: '+244', countryCode: 'AO', country: 'Angola' },
        { code: '+672', countryCode: 'AQ', country: 'Antarctica' },
        { code: '+54', countryCode: 'AR', country: 'Argentina' },
        { code: '+374', countryCode: 'AM', country: 'Armenia' },
        { code: '+297', countryCode: 'AW', country: 'Aruba' },
        { code: '+61', countryCode: 'AU', country: 'Australia' },
        { code: '+43', countryCode: 'AT', country: 'Austria' },
        { code: '+994', countryCode: 'AZ', country: 'Azerbaijan' },
        // Add more country codes as needed
    ];

    function getCountryInfo(countryCode = 'EG') {
        const country = countryCodes.find(country => country.countryCode === countryCode);

        if (country) {
            return `${country.code}`;
        } else {
            return 'Country code not found';
        }

    }

    return <>

        <div className="p-6 pe-8 overflow-y-auto rounded-t-xl bg-blue-gray-100 dark:bg-gray-900 flex justify-between items-center align-middle">
            <div className="">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative mt-1">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <i className='fa fa-search w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden="true"></i>
                    </div>
                    <input type="search"
                        id="table-search"
                        className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-48 sm:w-56 lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search for users..."
                        value={searchQuery} // Bind value to searchQuery state
                        onChange={handleInputChange} // Call handleInputChange on input change
                    />
                </div>
            </div>

            <button
                onClick={toggleModal}
                data-modal-target="crud-modal"
                data-modal-toggle="crud-modal"
                type="button"
                className="focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                Add New
            </button>
        </div>

        <div className="relative overflow-y-auto md:w-full sm:w-screen shadow-md sm:rounded-lg">

            {
                isLoading ? <div className="loading">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#199A8E"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassNameclassName=""
                        visible={true}
                    />
                </div> : allUsers.length === 0 ? <div className='flex justify-center align-middle p-12 text-teal-800'>
                    <h1 className='font-bold'>not found result try again</h1>
                </div> :
                    <table className="text-center overflow-y-auto w-full text-sm rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    #
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={ToggleSortName}>
                                        Full Name
                                        <span className='ms-2'> {sortName ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Email || Phone Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    joined
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Role
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Image
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    user ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Updated By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Update
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Recycle Bin
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {filteredUsers?.map((user, index) => <tr key={user._id} className="text-center bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {index + 1}
                                </th>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {highlightText(user.fullName, searchQuery)}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.email ?
                                        <div className='flex  justify-between items-center'>
                                            <div>{user.email}</div>
                                            <Link to={`https://mail.google.com/mail/?view=cm&fs=1&to=${user.email}`} target='_blank'
                                                className="ms-2 flex justify-center items-center w-10 h-10 rounded-full text-white bg-[#8a8a8a] hover:bg-gray-500">
                                                <i className='fa-regular fa-envelope text-xl'></i>
                                            </Link>
                                        </div>
                                        :
                                        <div className='flex  justify-between items-center'>
                                            <div><span className='text-teal-500 me-1'>{user.countryCode}:</span> {user.phoneNumber}</div>
                                            <div className="flex justify-center items-center gap-x-2">
                                                {/* whats app */}
                                                <Link to={`https://wa.me/${getCountryInfo(user.countryCode) || '+20'}${user.phoneNumber}`} target='_blank'
                                                    className="flex justify-center items-center w-10 h-10 rounded-full text-white bg-green-500 hover:bg-green-600">
                                                    <i className='fa-brands fa-whatsapp text-xl'></i>
                                                </Link>
                                                {/* telegram*/}
                                                {/* <Link to={`https://t.me/share/url?url=https://wa.me/${getCountryInfo(user.countryCode) || '+20'}${user.phoneNumber}`} target='_blank' */}
                                                <Link to={`https://t.me/${getCountryInfo(user.countryCode) || '+20'}${user.phoneNumber}`} target='_blank'
                                                    className="flex justify-center items-center w-10 h-10 rounded-full text-white bg-[#26A5E4] hover:bg-teal-500">
                                                    <i className='fa-brands fa-telegram text-xl'></i>
                                                </Link>
                                            </div>
                                        </div>

                                    }
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.status}
                                </td>
                                <td className="px-6 py-4 text-teal-700 font-extrabold whitespace-nowrap dark:text-white">
                                    {new Date(user.joined)?.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric' // Change '2-digit' to 'numeric' for full year
                                    })}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.role == "Admin" ? <span className='text-green-700 font-semibold'>
                                        Admin
                                        <i className="ms-1 fa fa-check-circle"></i>
                                    </span> :
                                        user.role == "Employee" ? <span className='text-gray-700 font-semibold'>
                                            Employee
                                            <i className="ms-1 fa fa-check-circle"></i>
                                        </span> : <span className='text-orange-700'>User</span>}
                                </td>
                                <td className="px-3 py-4">
                                    <img
                                        onClick={() => handleImageClick(user?.image)}
                                        data-modal-target="popup-modal-image"
                                        data-modal-toggle="popup-modal-image"
                                        src={user?.image} className='w-14 cursor-pointer'
                                        alt={user.fullName?.split(' ')?.slice(0, 2)?.join(' ') || "never yet Created"} />
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user._id}
                                </td>
                                <td className="px-6 py-4">
                                    {user?.createdBy?.fullName?.split(' ')?.slice(0, 2)?.join(' ') || "never yet Created"}
                                </td>
                                <td className="px-6 py-4">
                                    {user?.updatedBy?.fullName?.split(' ')?.slice(0, 2)?.join(' ') || "never yet Updated"}
                                </td>
                                <td className="px-6 py-4 mx-auto">
                                    <button
                                        onClick={() => handleUpdateClick(user)}
                                        data-modal-target="crud-modal-update"
                                        data-modal-toggle="crud-modal-update"
                                        type="button"
                                        className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:focus:ring-yellow-900">
                                        Update
                                    </button>
                                </td>
                                <td className="px-6 py-4 mx-auto">
                                    <button
                                        onClick={() => handleDeleteClick(user)}
                                        data-modal-target="popup-modal"
                                        data-modal-toggle="popup-modal"
                                        type="button"
                                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                                        Delete
                                    </button>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
            }

        </div >


        {/* Main modal add */}
        < div
            id="crud-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-full max-w-md max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Create New User
                        </h3>
                        <button
                            onClick={toggleModal}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formik.handleSubmit} className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label htmlFor="fullName" className="block mb-2 text-sm font-medium text-gray-900 ">Full Name <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="fullName"
                                    id="fullName"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.fullName}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Full Name..."
                                />

                                {formik.errors.fullName && formik.touched.fullName ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.fullName}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Email</label>
                                <input
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    id='email'
                                    name='email'
                                    type='email'
                                    autoComplete="email"
                                    placeholder='Type Email hear Ex: example@gmail.com...'
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                />
                                {formik.errors.email && formik.touched.email ? <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.email}</div> : ''}
                            </div>


                            <div className="flex justify-between align-middle mt-1 gap-x-1 col-span-2">

                                <div className='w-48'>

                                    <label htmlFor="countryCode" className="block mb-2 text-sm font-medium text-gray-900 cursor-pointer">
                                        Country Code
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="countryCode"
                                            name="countryCode"
                                            value={formik.values.countryCode}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="block w-full rounded-md border-0 py-1.5 font-semibold cursor-pointer text-gray-600 ps-2 px-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        >
                                            <option value="" disabled>Select Country Code</option>
                                            {countryCodes.map((country, index) => (
                                                <option key={index} value={country?.countryCode}>{country?.country} ({country?.code})</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                                        phone number
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.phoneNumber}
                                            id='phoneNumber'
                                            name='phoneNumber'
                                            type='text'
                                            autoComplete="phoneNumber"
                                            className="block w-full rounded-md border-0 py-1.5 font-semibold text-gray-900 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />

                                    </div>
                                </div>
                            </div>

                            {formik.errors.phoneNumber && formik.touched.phoneNumber ? <div className="py-3 mt-1 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.phoneNumber}</div> : ''}
                            {formik.errors.countryCode && formik.touched.countryCode ? <div className="py-3 mt-1 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.countryCode}</div> : ''}


                            <div className="col-span-2">
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Password <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    id='password'
                                    name='password'
                                    type='text'
                                    placeholder='Type password hear...'
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                />
                                {formik.errors.password && formik.touched.password ? <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.password}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900">User Role <span className='text-red-600 font-extrabold '>*</span></label>
                                <div>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            id="User"
                                            name="role"
                                            value="User"
                                            checked={formik.values.role === "User"}
                                            onChange={formik.handleChange}
                                            className="mr-2"
                                        />
                                        <span>User</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="Admin"
                                            name="role"
                                            value="Admin"
                                            checked={formik.values.role === "Admin"}
                                            onChange={formik.handleChange}
                                            className="mr-2"
                                        />
                                        <span>Admin</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="Employee"
                                            name="role"
                                            value="Employee"
                                            checked={formik.values.role === "Employee"}
                                            onChange={formik.handleChange}
                                            className="mr-2"
                                        />
                                        <span>Employee</span>
                                    </label>
                                </div>
                                {formik.errors.role && formik.touched.role ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formik.errors.role}
                                    </div>
                                ) : ''}
                            </div>


                            {/* image */}
                            <div className="col-span-2 mb-2">
                                <label htmlFor="userCode" className="block mb-2 text-sm font-medium text-gray-900">Image</label>
                                <input
                                    id="image"
                                    name="image"
                                    type="file"
                                    onChange={(e) => {
                                        handelImage(e);
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                />
                                {formik.errors.image && formik.touched.image && (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.image}</div>
                                )}
                            </div>

                        </div>

                        <button
                            // disabled={!(formikUpdate.isValid && formik.dirty)}
                            type='submit'
                            className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >

                            <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                            Add new user
                        </button>

                        {error ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{error}</div> : ''}
                    </form>

                </div>
            </div>
        </ div >

        {/* Main modal update */}
        < div
            id="crud-modal-update"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-full max-w-md max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Update user
                        </h3>
                        <button
                            onClick={toggleModalUpdate}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formikUpdate.handleSubmit} className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label htmlFor="fullName" className="block mb-2 text-sm font-medium text-gray-900 ">Full Name</label>
                                <input
                                    type="text"
                                    name="fullName"
                                    id="fullName"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.fullName}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateUserData?.fullName || "Type Full Name..."}
                                />

                                {formikUpdate.errors.fullName && formikUpdate.touched.fullName ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.fullName}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Email</label>
                                <input
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.email}
                                    id='email'
                                    name='email'
                                    type='email'
                                    autoComplete="email"
                                    placeholder={updateUserData?.email || "not exist email" || 'Type Email hear Ex: example@gmail.com...'}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                />
                                {formikUpdate.errors.email && formikUpdate.touched.email ? <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.email}</div> : ''}
                            </div>


                            <div className="flex justify-between align-middle mt-1 gap-x-1 col-span-2">

                                <div className='w-48'>

                                    <label htmlFor="countryCode" className="block mb-2 text-sm font-medium text-gray-900 cursor-pointer">
                                        Country Code
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="countryCode"
                                            name="countryCode"
                                            value={formikUpdate.values.countryCode}
                                            onChange={formikUpdate.handleChange}
                                            onBlur={formikUpdate.handleBlur}
                                            className="block w-full rounded-md border-0 py-1.5 font-semibold cursor-pointer text-gray-600 ps-2 px-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        >
                                            <option value={updateUserData?.countryCode || "Egypt"} disabled>Select Country Code</option>
                                            {countryCodes.map((country, index) => (
                                                <option key={index} value={country?.countryCode}>{country?.country} ({country?.code})</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                                        phone number
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            onBlur={formikUpdate.handleBlur}
                                            onChange={formikUpdate.handleChange}
                                            value={formikUpdate.values.phoneNumber}
                                            id='phoneNumber'
                                            name='phoneNumber'
                                            type='text'
                                            autoComplete="phoneNumber"
                                            placeholder={updateUserData?.phoneNumber || "not exist Phone Number"}
                                            className="block w-full rounded-md border-0 py-1.5 font-semibold text-gray-900 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />

                                    </div>
                                </div>
                            </div>

                            {formikUpdate.errors.phoneNumber && formikUpdate.touched.phoneNumber ? <div className="py-3 mt-1 px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.phoneNumber}</div> : ''}
                            {formikUpdate.errors.countryCode && formikUpdate.touched.countryCode ? <div className="py-3 mt-1 px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.countryCode}</div> : ''}


                            <div className="col-span-2">
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Password</label>
                                <input
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.password}
                                    id='password'
                                    name='password'
                                    type='text'
                                    placeholder={'Type password hear...'}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                />
                                {formikUpdate.errors.password && formikUpdate.touched.password ? <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.password}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900">User Role</label>
                                <div>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            id="User"
                                            name="role"
                                            value="User"
                                            checked={formikUpdate.values.role === "User"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>User</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="Admin"
                                            name="role"
                                            value="Admin"
                                            checked={formikUpdate.values.role === "Admin"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>Admin</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="Employee"
                                            name="role"
                                            value="Employee"
                                            checked={formikUpdate.values.role === "Employee"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>Employee</span>
                                    </label>
                                </div>
                                {formikUpdate.errors.role && formikUpdate.touched.role ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formikUpdate.errors.role}
                                    </div>
                                ) : ''}
                            </div>

                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900">User Confirm Account</label>
                                <div>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            id="true"
                                            name="confirmAccount"
                                            value={true}
                                            checked={formikUpdate.values.confirmAccount === "true"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>Yes</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="false"
                                            name="confirmAccount"
                                            value={false}
                                            checked={formikUpdate.values.confirmAccount === "false"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>No</span>
                                    </label>
                                </div>
                                {formikUpdate.errors.confirmAccount && formikUpdate.touched.confirmAccount ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formikUpdate.errors.confirmAccount}
                                    </div>
                                ) : ''}
                            </div>



                            {/* image */}
                            <div className="col-span-2 mb-2">
                                <label htmlFor="userCode" className="block mb-2 text-sm font-medium text-gray-900">Image</label>
                                <input
                                    id="image"
                                    name="image"
                                    type="file"
                                    onChange={(e) => {
                                        handelImageUpdate(e);
                                        formikUpdate.handleChange(e);
                                    }}
                                    onBlur={formikUpdate.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                />
                                {formikUpdate.errors.image && formikUpdate.touched.image && (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.image}</div>
                                )}
                            </div>

                        </div>

                        <button
                            // disabled={!(formikUpdate.isValid && formikUpdate.dirty)}
                            type='submit'
                            className="text-white inline-flex items-center bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
                        >

                            {/* <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg> */}
                            Update User
                        </button>

                        {errorUpdate ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{errorUpdate}</div> : ''}
                    </form>
                </div>
            </div>
        </ div >


        {/* delete model */}
        < div
            id="popup-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal"
                        onClick={toggleModelDel}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg
                            className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                        </svg>
                        <h3 className="mb-6 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Move this User to the Recycle Bin ?
                        </h3>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                            onClick={submitDeleteUser}
                        >
                            Yes, I'm sure
                        </button>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            onClick={toggleModelDel}
                            className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            No, cancel
                        </button>
                    </div>
                </div>
            </div>
        </ div>



        {/* image model */}
        <div
            id="popup-modal-image"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-6 w-full max-w-md max-h-full">
                <div className="relative bg-[#ffffffd1] rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal-image"
                        onClick={toggleModelImg}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>

                    <div className="p-1 md:p-2 text-center">

                        <img src={imageSrc || ""} alt="" className='w-full h-full p-3 py-14' />



                        <button
                            data-modal-hide="popup-modal-image"
                            type="button"
                            onClick={toggleModelImg}
                            className="absolute bottom-0.5 right-2 py-2 px-4 ms-3 text-md font-medium text-white focus:outline-none bg-teal-500 hover:bg-teal-700 focus:bg-teal-700 rounded-xl border border-gray-200 focus:z-10 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            close
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </>
}
