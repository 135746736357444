import axios from 'axios';
import React, { useContext, useState } from 'react'
import { BallTriangle } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { UserContext } from '../../Context/UserContext.js';
import { Helmet } from 'react-helmet';


export default function Location() {

    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [error, setError] = useState(null)
    const [errorUpdate, setErrorUpdate] = useState(null)

    const [sortName, setSortName] = useState(false);
    const ToggleSortName = () => {
        setSortName(!sortName);
    };

    // const [sortLocationCode, setSortLocationCode] = useState(false);
    // const ToggleSortLocationCode = () => {
    //     setSortLocationCode(!sortLocationCode);
    // };

    const handleInputChange = (event) => { // Function to handle input change
        setSearchQuery(event.target.value); // Update searchQuery state with input value
    };


    async function getAllLocations(searchQuery) {

        return await axios.get(`${process.env.REACT_APP_BACK_END}location?search=${searchQuery}${sortName ? "&sort=name" : ''}`)
    }

    let { isLoading, data, refetch } = useQuery(['AllLocations', searchQuery], () => getAllLocations(searchQuery), {
        cacheTime: 9000000
    })

    let allLocations = []

    if (data?.data?.message == "success") {

        allLocations = data?.data?.location
    }


    // sortLocationCode ? refetch() : refetch()
    sortName ? refetch() : refetch()


    // modal
    const toggleModal = () => {
        const modal = document.getElementById('crud-modal');
        modal.classList.toggle('hidden');
        modal.setAttribute('aria-hidden', modal.classList.contains('hidden'));
    };

    const toggleModalUpdate = () => {
        const modalUpdate = document?.getElementById('crud-modal-update');
        modalUpdate?.classList?.toggle('hidden');
        modalUpdate?.setAttribute('aria-hidden', modalUpdate?.classList?.contains('hidden'));
    };

    const toggleModelDel = () => {
        const modalDelete = document.getElementById('popup-modal');
        modalDelete.classList.toggle('hidden');
        modalDelete.setAttribute('aria-hidden', modalDelete.classList.contains('hidden'));
    }


    const toggleModelImg = () => {
        const modalImage = document.getElementById('popup-modal-image');
        modalImage.classList.toggle('hidden');
        modalImage.setAttribute('aria-hidden', modalImage.classList.contains('hidden'));
    }

    // Function to handle Image button click
    const [imageSrc, setImageSrc] = useState('');

    const handleImageClick = (img) => {
        setImageSrc(img); // Set the src of the Brand being image
        toggleModelImg(); // Show the img modal
    };


    // add location
    const [image, setImage] = useState(null); // Use null instead of empty string for files
    function handelImage(e) {
        setImage(e.target.files[0])
    }

    // Function to submit add location
    async function submitAddLocation(values) {
        const formData = new FormData();
        formData.append('image', image);
        formData.append('name', values.name);
        formData.append('locationCode', values.locationCode);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACK_END}location`,
                formData,
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                        'Content-Type': 'multipart/form-data', // Set content type for FormData
                    },
                }
            );

            await refetch();
            formik.resetForm();
            toggleModal()
            toast.success('Location added successfully!');

        } catch (error) {
            setError(error?.response?.data?.message)
        }
    }

    let validateSchemaAdd = Yup.object({
        name: Yup.string().min(3, 'location name is minLength 3').max(30, 'location name is maxLength 30').required('location name is required'),
        locationCode: Yup.string().min(1, 'location code is minLength 1').max(30, 'location code is maxLength 30').required('location code is required'),
        image: Yup.mixed().required('Image is required'),
    })

    let formik = useFormik({
        initialValues: {
            name: '',
            locationCode: '',
            image: null, // Use null for file initial value

        }, validationSchema: validateSchemaAdd,
        onSubmit: submitAddLocation

    })


    // update location

    const [imageUpdate, setImageUpdate] = useState(null); // Use null instead of empty string for files
    function handelImageUpdate(e) {
        setImageUpdate(e.target.files[0])
    }

    const [updateLocationData, setUpdateLocationData] = useState(null);
    const [updatingLocationId, setUpdatingLocationId] = useState(null);

    // Function to handle update button click
    const handleUpdateClick = (location) => {
        setUpdateLocationData(location); // Set the location data to state
        setUpdatingLocationId(location?._id); // Set the ID of the location being updated
        toggleModalUpdate(); // Show the update modal
    };


    // Function to submit update location
    async function submitUpdateLocation(values) {
        try {

            const formData = new FormData();

            if (imageUpdate === "" || imageUpdate === null) {
                // If image is an empty string, don't append anything
            } else {
                // If image is not an empty string, append it to formData
                formData.append('image', imageUpdate);
            }

            if (values.name === "") {
                // If values.name is an empty string, don't append anything
            } else {
                // If values.name is not an empty string, append it to formData
                formData.append('name', values.name || updateLocationData.name);
            }

            if (values.locationCode === "") {
                // If values.locationCode is an empty string, don't append anything
            } else {
                // If values.locationCode is not an empty string, append it to formData
                formData.append('locationCode', values.locationCode || updateLocationData.locationCode);
            }

            const response = await axios.put(
                `${process.env.REACT_APP_BACK_END}location/${updatingLocationId}`,
                formData,
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();
            formikUpdate.resetForm();
            setImageUpdate(null)
            toggleModalUpdate()
            toast.success('Location updated successfully!');

        } catch (error) {
            setErrorUpdate(error?.response?.data?.message)
        }
    }

    let validateSchemaUpdate = Yup.object({
        name: Yup.string().min(3, 'location name is minLength 3').max(30, 'location name is maxLength 30'),
        locationCode: Yup.string().min(1, 'location code is minLength 1').max(30, 'location code is maxLength 30'),
        // image: Yup.mixed(),
    })

    let formikUpdate = useFormik({
        initialValues: {
            name: '',
            locationCode: '',
            // image: null, // Use null for file initial value

        }, validationSchema: validateSchemaUpdate,
        onSubmit: submitUpdateLocation

    })

    // end update location


    // delete location
    const [deleteLocationId, setDeleteLocationId] = useState(null);

    // Function to handle update button click
    const handleDeleteClick = (location) => {
        setDeleteLocationId(location?._id); // Set the ID of the location being updated
        toggleModelDel(); // Show the update modal
    };

    // Function to submit update location
    async function submitDeleteLocation() {

        const formData = new FormData();
        formData.append('isDeleted', 'true');

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACK_END}location/${deleteLocationId}`,
                formData,
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();
            toggleModelDel()
            toast.success('Location delete successfully!');
        } catch (error) {
            setError(error?.response?.data?.message)
        }
    }

    return <>

        <Helmet>
            <meta name='description' content='Locations Page' charSet="utf-8" />
            <title>Locations</title>
        </Helmet>

        <div className="p-6 pe-8 overflow-y-auto rounded-t-xl bg-blue-gray-100 dark:bg-gray-900 flex justify-between align-middle">
            <div className="">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative mt-1">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <i className='fa fa-search w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden="true"></i>
                    </div>
                    <input type="search"
                        id="table-search"
                        className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-48 sm:w-56 lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search for Location..."
                        value={searchQuery} // Bind value to searchQuery state
                        onChange={handleInputChange} // Call handleInputChange on input change
                    />
                </div>
            </div>

            <button
                onClick={toggleModal}
                data-modal-target="crud-modal"
                data-modal-toggle="crud-modal"
                type="button"
                className="focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                Add New
            </button>
        </div>

        <div className="relative overflow-y-auto md:w-full sm:w-screen shadow-md sm:rounded-lg">

            {
                isLoading ? <div className="loading">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#199A8E"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassNameclassName=""
                        visible={true}
                    />
                </div> : allLocations.length === 0 ? <div className='flex justify-center align-middle p-12 text-teal-800'>
                    <h1 className='font-bold'>not found result try again</h1>
                </div> :
                    <table className="text-center overflow-y-auto w-full text-sm rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    #
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={ToggleSortName}>
                                        Name
                                        <span className='ms-2'> {sortName ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th>
                                {/* <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={ToggleSortLocationCode}>
                                        Location Code
                                        <span className='ms-2'> {sortLocationCode ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th> */}
                                <th scope="col" className="px-6 py-3">
                                    Location Code
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Image
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Location  ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Updated By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Update
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Recycle Bin
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {allLocations.map((location, index) => <tr key={location._id} className="text-center bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {index + 1}
                                </th>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {location.name}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {location.locationCode}
                                </td>
                                <td className="px-3 py-4">
                                    <img
                                        onClick={() => handleImageClick(location?.image)}
                                        data-modal-target="popup-modal-image"
                                        data-modal-toggle="popup-modal-image"
                                        src={location?.image} className='w-14 cursor-pointer' alt={location.name} />
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {location._id}
                                </td>
                                <td className="px-6 py-4">
                                    {location?.createdBy?.fullName?.split(' ')?.slice(0, 2)?.join(' ') || "never yet Created"}
                                </td>
                                <td className="px-6 py-4">
                                    {location?.updatedBy?.fullName?.split(' ')?.slice(0, 2)?.join(' ') || "never yet Updated"}
                                </td>
                                <td className="px-6 py-4 mx-auto">
                                    <button
                                        // onClick={toggleModalUpdate}
                                        onClick={() => handleUpdateClick(location)}
                                        data-modal-target="crud-modal-update"
                                        data-modal-toggle="crud-modal-update"
                                        type="button"
                                        className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:focus:ring-yellow-900">
                                        Update
                                    </button>
                                </td>
                                <td className="px-6 py-4 mx-auto">
                                    <button
                                        // onClick={toggleModelDel}
                                        onClick={() => handleDeleteClick(location)}
                                        data-modal-target="popup-modal"
                                        data-modal-toggle="popup-modal"
                                        type="button"
                                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                                        Delete
                                    </button>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
            }

        </div>


        {/* Main modal add */}
        <div
            id="crud-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-full max-w-md max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Create New Location
                        </h3>
                        <button
                            onClick={toggleModal}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formik.handleSubmit} className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">Location Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Location name ex:Egypt.."
                                />

                                {formik.errors.name && formik.touched.name ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.name}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="locationCode" className="block mb-2 text-sm font-medium text-gray-900 ">Location Code</label>
                                <input
                                    type="text"
                                    name="locationCode"
                                    id="locationCode"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.locationCode}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Location Code ex:EG.."
                                />

                                {formik.errors.locationCode && formik.touched.locationCode ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.locationCode}</div> : ''}
                            </div>

                            {/* image */}
                            <div className="col-span-2">
                                <label htmlFor="locationCode" className="block mb-2 text-sm font-medium text-gray-900">Image</label>
                                <input
                                    id="image"
                                    name="image"
                                    type="file"
                                    onChange={(e) => {
                                        handelImage(e);
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                />
                                {formik.errors.image && formik.touched.image && (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.image}</div>
                                )}
                            </div>

                        </div>

                        <button
                            disabled={!(formik.isValid && formik.dirty)}
                            type='submit'
                            className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >

                            <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                            Add new Location
                        </button>

                        {error ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{error}</div> : ''}
                    </form>
                </div>
            </div>
        </div>


        {/* Main modal update */}
        <div
            id="crud-modal-update"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-full max-w-md max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Update Location
                        </h3>
                        <button
                            onClick={toggleModalUpdate}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formikUpdate.handleSubmit} className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">Location Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.name}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateLocationData?.name || "Type Location name ex:Egypt.."}
                                />

                                {formikUpdate.errors.name && formikUpdate.touched.name ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.name}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="locationCode" className="block mb-2 text-sm font-medium text-gray-900 ">Location Code</label>
                                <input
                                    type="text"
                                    name="locationCode"
                                    id="locationCode"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.locationCode}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateLocationData?.locationCode || "Type Location Code ex:EG.."}
                                />

                                {formikUpdate.errors.locationCode && formikUpdate.touched.locationCode ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.locationCode}</div> : ''}
                            </div>

                            {/* image */}
                            <div className="col-span-2">
                                <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900">Image</label>
                                <input
                                    id="image"
                                    name="image"
                                    type="file"
                                    onChange={(e) => {
                                        handelImageUpdate(e);
                                        formikUpdate.handleChange(e);
                                    }}
                                    onBlur={formikUpdate.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                />
                                {formikUpdate.errors.image && formikUpdate.touched.image && (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.image}</div>
                                )}
                            </div>
                        </div>

                        <button
                            // disabled={!(formikUpdate.isValid && formikUpdate.dirty)}
                            type='submit'
                            className="text-white inline-flex items-center bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
                        >

                            {/* <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg> */}
                            Update Location
                        </button>

                        {errorUpdate ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{errorUpdate}</div> : ''}
                    </form>
                </div>
            </div>
        </div>



        {/* delete model */}
        <div
            id="popup-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal"
                        onClick={toggleModelDel}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg
                            className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                        </svg>
                        <h3 className="mb-6 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Move this location to the Recycle Bin ?
                        </h3>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                            onClick={submitDeleteLocation}
                        >
                            Yes, I'm sure
                        </button>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            onClick={toggleModelDel}
                            className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            No, cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>


        {/* image model */}
        <div
            id="popup-modal-image"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-6 w-full max-w-md max-h-full">
                <div className="relative bg-[#ffffffd1] rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal-image"
                        onClick={toggleModelImg}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>

                    <div className="p-1 md:p-2 text-center">

                        <img src={imageSrc || ""} alt="" className='w-full h-full p-3 py-14' />



                        <button
                            data-modal-hide="popup-modal-image"
                            type="button"
                            onClick={toggleModelImg}
                            className="absolute bottom-0.5 right-2 py-2 px-4 ms-3 text-md font-medium text-white focus:outline-none bg-teal-500 hover:bg-teal-700 focus:bg-teal-700 rounded-xl border border-gray-200 focus:z-10 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            close
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </>
}
