import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BallTriangle } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default function Coupon() {

    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [error, setError] = useState(null)
    const [errorUpdate, setErrorUpdate] = useState(null)

    const [dropdownOpenButton2, setDropdownOpenButton2] = useState(false);

    const [sortAmount, setSortAmount] = useState(false);
    const ToggleSortAmount = () => {
        setSortAmount(!sortAmount);
    };

    const toggleDropdownButton2 = () => {
        setDropdownOpenButton2(!dropdownOpenButton2);
    };

    const handleInputChange = (event) => { // Function to handle input change
        setSearchQuery(event.target.value); // Update searchQuery state with input value
    };

    async function getCoupons(searchQuery) {

        return await axios.get(`${process.env.REACT_APP_BACK_END}coupon/toDashboard?search=${searchQuery}${sortAmount ? "&sort=-amount" : ''}`)
    }

    let { isLoading, data, refetch } = useQuery(['Coupons', searchQuery], () => getCoupons(searchQuery), {
        cacheTime: 9000000
    })

    let allCoupons = []

    if (data?.data?.message == "success") {

        allCoupons = data?.data?.coupon
    }

    sortAmount ? refetch() : refetch()

    // modal
    const toggleModal = () => {
        const modal = document.getElementById('crud-modal');
        modal.classList.toggle('hidden');
        modal.setAttribute('aria-hidden', modal.classList.contains('hidden'));
    };

    const toggleModalUpdate = () => {
        const modalUpdate = document?.getElementById('crud-modal-update');
        modalUpdate?.classList?.toggle('hidden');
        modalUpdate?.setAttribute('aria-hidden', modalUpdate?.classList?.contains('hidden'));
    };

    const toggleModelDel = () => {
        const modalDelete = document.getElementById('popup-modal');
        modalDelete.classList.toggle('hidden');
        modalDelete.setAttribute('aria-hidden', modalDelete.classList.contains('hidden'));
    }


    // add coupon

    // location
    const [locations, setLocations] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);

    useEffect(() => {
        async function fetchLocations() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACK_END}location`);
                setLocations(response?.data?.location);
            } catch (error) {
            }
        }

        fetchLocations();
    }, []);

    const handleChangeLocation = (event) => {
        const locationId = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            setSelectedLocations([...selectedLocations, locationId]);
        } else {
            setSelectedLocations(selectedLocations.filter(id => id !== locationId));
        }
    };
    // end location

    // brand
    const [brand, setBrand] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([]);

    useEffect(() => {
        async function fetchBrand() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACK_END}brand/toDashboard`);
                setBrand(response?.data?.brand);
            } catch (error) {
            }
        }

        fetchBrand();
    }, []);

    const handleChangeBrand = (event) => {
        const brandId = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            setSelectedBrand([...selectedBrand, brandId]);
        } else {
            setSelectedBrand(selectedBrand.filter(id => id !== brandId));
        }
    };
    // end brand

    // category
    const [category, setCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);

    useEffect(() => {
        async function fetchCategory() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACK_END}category/toDashboard`);
                setCategory(response?.data?.category);
            } catch (error) {
            }
        }

        fetchCategory();
    }, []);

    const handleChangeCategory = (event) => {
        const categoryId = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            setSelectedCategory([...selectedCategory, categoryId]);
        } else {
            setSelectedCategory(selectedCategory.filter(id => id !== categoryId));
        }
    };

    // end category

    // add Coupons
    async function submitAddCoupon(values) {

        // Append selected locations
        values.location = [...selectedLocations];
        // Append selected categories
        values.category = [...selectedCategory];
        // Append selected brands
        values.brand = [...selectedBrand];

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACK_END}coupon`,
                values,
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();
            formik.resetForm();
            toggleModal()
            setSelectedLocations([]); // Reset selected locations
            setSelectedCategory([]); // Reset selected Category
            setSelectedBrand([]); // Reset selected Brand
            toast.success('coupon added successfully!');
        } catch (error) {
            setError(error?.response?.data?.message)
        }
    }

    let validateSchemaAdd = Yup.object({
        code: Yup.string().max(50, 'Code is too long').default(''),
        en_description: Yup.string().min(3, 'Category description is too short').max(150, 'Category description is too long').required('Category description is required'),
        ar_description: Yup.string().min(3, 'Category description is too short').max(150, 'Category description is too long').required('Category description is required'),
        en_status: Yup.string().oneOf(['discount', 'cashback']).required().default('discount'),
        ar_status: Yup.string().oneOf(['خصم', 'كاش باك']).required().default('خصم'),
        amount: Yup.number().positive().min(1).max(100),
        expire: Yup.date(),
        usedCount: Yup.number().min(0).integer(),
        likeCount: Yup.number().min(0).integer(),
        dislikeCount: Yup.number().min(0).integer(),
        category: Yup.array().of(Yup.string().required()).required(),
        brand: Yup.array().of(Yup.string().required()).required(),
        location: Yup.array().of(Yup.string().required()).required(),
        link: Yup.string().url('Invalid Link'),

    });

    let formik = useFormik({
        initialValues: {
            code: '',
            en_description: '',
            ar_description: '',
            en_status: 'discount',
            ar_status: 'خصم',
            link: '',
            amount: 0,
            expire: '',
            usedCount: 0,
            likeCount: 0,
            dislikeCount: 0,
            category: [],
            brand: [],
            location: []
        },
        validationSchema: validateSchemaAdd,
        onSubmit: submitAddCoupon
    });

    // end add coupon


    // update coupon
    const [updateCouponData, setUpdateCouponData] = useState(null);
    const [updatingCouponId, setUpdatingCouponId] = useState(null);

    // Function to handle update button click
    const handleUpdateClick = (coupon) => {
        setUpdateCouponData(coupon); // Set the coupon data to state
        setUpdatingCouponId(coupon?._id); // Set the ID of the coupon being updated
        toggleModalUpdate(); // Show the update modal
    };

    async function submitUpdateCoupon(values) {

        try {
            // location
            let locationPrevious = [...updateCouponData.location].map((location) => location._id);

            values.location = selectedLocations.length === 0 ? [...locationPrevious] : [...selectedLocations];

            // category
            let categoryPrevious = [...updateCouponData.category].map((category) => category._id);

            values.category = selectedCategory.length === 0 ? [...categoryPrevious] : [...selectedCategory];

            // brand
            let brandPrevious = [...updateCouponData.brand].map((brand) => brand._id);

            values.brand = selectedBrand.length === 0 ? [...brandPrevious] : [...selectedBrand];


            if (values.code === "") {
                // If values.code is an empty string, don't append anything
            } else {
                // If values.code is not an empty string, append it to formData
                values.code = values.code || updateCouponData.code
            }

            if (values.en_description === "") {
                // If values.en_description is an empty string, don't append anything
            } else {
                // If values.en_description is not an empty string, append it to formData
                values.en_description = values.en_description || updateCouponData.en_description
            }

            if (values.ar_description === "") {
                // If values.ar_description is an empty string, don't append anything
            } else {
                // If values.ar_description is not an empty string, append it to formData
                values.ar_description = values.ar_description || updateCouponData.ar_description
            }

            if (values.en_status === "") {
                // If values.en_status is an empty string, don't append anything
            } else {
                // If values.en_status is not an empty string, append it to formData
                values.en_status = values.en_status || updateCouponData.en_status
            }

            if (values.ar_status === "") {
                // If values.ar_status is an empty string, don't append anything
            } else {
                // If values.ar_status is not an empty string, append it to formData
                values.ar_status = values.ar_status || updateCouponData.ar_status
            }

            if (values.amount === "") {
                // If values.amount is an empty string, don't append anything
            } else {
                // If values.amount is not an empty string, append it to formData
                values.amount = values.amount || updateCouponData.amount
            }

            if (values.likeCount === "") {
                // If values.likeCount is an empty string, don't append anything
            } else {
                // If values.likeCount is not an empty string, append it to formData
                values.likeCount = values.likeCount || updateCouponData.likeCount
            }

            if (values.usedCount === "") {
                // If values.usedCount is an empty string, don't append anything
            } else {
                // If values.usedCount is not an empty string, append it to formData
                values.usedCount = values.usedCount || updateCouponData.usedCount
            }

            if (values.dislikeCount === "") {
                // If values.dislikeCount is an empty string, don't append anything
            } else {
                // If values.dislikeCount is not an empty string, append it to formData
                values.dislikeCount = values.dislikeCount || updateCouponData.dislikeCount
            }

            if (values.expire === "") {
                // If values.expire is an empty string, don't append anything
            } else {
                // If values.expire is not an empty string, append it to formData
                values.expire = values.expire || updateCouponData.expire
            }

            if (values.link === "") {
                // If values.expire is an empty string, don't append anything
            } else {
                // If values.expire is not an empty string, append it to formData
                values.link = values.link || updateCouponData.link
            }

            const response = await axios.put(
                `${process.env.REACT_APP_BACK_END}coupon/${updatingCouponId}`,
                values,
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();
            formikUpdate.resetForm();
            toggleModalUpdate()
            setSelectedLocations([]); // Reset selected locations
            setSelectedCategory([]); // Reset selected Category
            setSelectedBrand([]); // Reset selected Brand
            toast.success('coupon Updated successfully!');
        } catch (error) {
            setErrorUpdate(error?.response?.data?.message)
        }
    }

    let validateSchemaUpdate = Yup.object({
        code: Yup.string().min(1, 'Code is too short').max(50, 'Code is too long'),
        en_description: Yup.string().min(3, 'En description is too short').max(255, 'En description is too long'),
        ar_description: Yup.string().min(3, 'Ar description is too short').max(255, 'Ar description is too long'),
        en_status: Yup.string().oneOf(['discount', 'cashback']),
        ar_status: Yup.string().oneOf(['خصم', 'كاش باك']),
        amount: Yup.number().positive().min(1).max(100),
        expire: Yup.date(),
        usedCount: Yup.number().min(0).integer(),
        likeCount: Yup.number().min(0).integer(),
        dislikeCount: Yup.number().min(0).integer(),
        category: Yup.array().of(Yup.string()),
        brand: Yup.array().of(Yup.string()),
        location: Yup.array().of(Yup.string()),
        link: Yup.string().url('Invalid Link'),

    });

    let formikUpdate = useFormik({
        initialValues: {
            code: '',
            en_description: '',
            ar_description: '',
            en_status: '',
            ar_status: '',
            amount: '',
            link: '',
            expire: '',
            usedCount: '',
            likeCount: '',
            dislikeCount: '',
            category: [],
            brand: [],
            location: []
        },
        validationSchema: validateSchemaUpdate,
        onSubmit: submitUpdateCoupon
    });




    // end update coupon


    // delete coupon
    const [deleteCouponId, setDeleteCouponId] = useState(null);

    // Function to handle update button click
    const handleDeleteClick = (coupon) => {
        setDeleteCouponId(coupon?._id); // Set the ID of the coupon being updated
        toggleModelDel(); // Show the update modal
    };

    // Function to submit update coupon
    async function submitDeleteCoupon() {

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACK_END}coupon/${deleteCouponId}`,
                { isDeleted: true },
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();
            toggleModelDel()
            toast.success('coupon Moved to Recycle bin successfully!');
        } catch (error) {
            setError(error?.response?.data?.message)
        }
    }

    return <>

        <Helmet>
            <meta name='description' content='Coupons Page' charSet="utf-8" />
            <title>Coupons</title>
        </Helmet>


        <div className="p-6 pe-8 overflow-y-auto rounded-t-xl bg-blue-gray-100 dark:bg-gray-900 flex justify-between align-middle">
            <div className="">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative mt-1">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <i className='fa fa-search w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden="true"></i>
                    </div>
                    <input type="search"
                        id="table-search"
                        className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-48 sm:w-56 lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search for Coupons..."
                        value={searchQuery} // Bind value to searchQuery state
                        onChange={handleInputChange} // Call handleInputChange on input change
                    />
                </div>
            </div>

            <button
                onClick={toggleModal}
                data-modal-target="crud-modal"
                data-modal-toggle="crud-modal"
                type="button"
                className="focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                Add New
            </button>
        </div>

        <div className="relative overflow-y-auto md:w-full sm:w-screen shadow-md sm:rounded-lg">

            {
                isLoading ? <div className="loading">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#199A8E"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassNameclassName=""
                        visible={true}
                    />
                </div> : allCoupons.length === 0 ? <div className='flex justify-center align-middle p-12 text-teal-800'>
                    <h1 className='font-bold'>not found result try again</h1>
                </div> :
                    <table className="text-center w-full text-sm rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    #
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Code
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={ToggleSortAmount}>
                                        Discount
                                        <span className='ms-2'> {sortAmount ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Link
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Description EN & AR
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Expire
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Locations
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Categories
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Brands
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={toggleDropdownButton2}>
                                        Use Count
                                        {/* <span className='ms-2'> {dropdownOpenButton2 ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span> */}
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={toggleDropdownButton2}>
                                        Like Count
                                        {/* <span className='ms-2'> {dropdownOpenButton2 ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span> */}
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={toggleDropdownButton2}>
                                        DisLike Count
                                        {/* <span className='ms-2'> {dropdownOpenButton2 ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span> */}
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    coupon ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Updated By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Update
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Recycle BIN
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {allCoupons.map((coupon, index) => <tr key={coupon._id} className="text-center bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                                <th scope="row" className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {index + 1}
                                </th>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon.code}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon.amount}
                                </td>
                                <td className="px-3 py-4 font-medium whitespace-nowrap dark:text-white">
                                    <Link to={coupon.link} target='_blank' className="text-blue-500 hover:text-blue-700">
                                        {coupon.link}
                                    </Link>
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {coupon.description.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {coupon?.description?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-center'>
                                        <span className={`${coupon?.status?.en == 'discount' ? 'text-green-700' : 'text-orange-400'}`}>{coupon.status.en}</span>
                                    </h6>
                                </td>
                                <td className="px-6 py-4 text-teal-700 font-extrabold whitespace-nowrap dark:text-white">
                                    {
                                        coupon?.expire ?
                                            new Date(coupon.expire)?.toLocaleDateString('en-GB', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric' // Change '2-digit' to 'numeric' for full year
                                            })
                                            :
                                            'not existing Expiration'
                                    }
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon?.location?.map((location, index) => <h6 key={location._id} className='mb-2  pb-1 border-b-2 border-gray-200'>{location.name}</h6>)}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon?.category?.map((category, index) => <div key={index}>
                                        <h6 className='mb-2 pb-1 border-b-2 border-gray-200'>
                                            {category?.name.en}
                                        </h6>
                                    </div>)}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon?.brand?.map((brand, index) => <div key={index}>
                                        <h6 className='mb-2 pb-1 border-b-2 border-gray-200'>
                                            {brand?.name.en}
                                        </h6>
                                    </div>)}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon.usedCount}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon.likeCount}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon.dislikeCount}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon._id}
                                </td>
                                <td className="px-3 py-4">
                                    {coupon?.createdBy?.fullName.split(' ')?.slice(0, 2)?.join(' ') || "never yet Created"}
                                </td>
                                <td className="px-3 py-4">
                                    {coupon?.updatedBy?.fullName.split(' ')?.slice(0, 2)?.join(' ') || "never yet updated"}
                                </td>
                                <td className="px-3 py-4 mx-auto">
                                    <button
                                        onClick={() => handleUpdateClick(coupon)}
                                        data-modal-target="crud-modal-update"
                                        data-modal-toggle="crud-modal-update"
                                        type="button"
                                        className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:focus:ring-yellow-900">
                                        Update
                                    </button>
                                </td>
                                <td className="px-3 py-4 mx-auto">
                                    <button
                                        onClick={() => handleDeleteClick(coupon)}
                                        data-modal-target="popup-modal"
                                        data-modal-toggle="popup-modal"
                                        type="button"
                                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                                        Delete
                                    </button>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
            }

        </div>



        {/* Main modal add */}
        <div
            id="crud-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-full max-w-md max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Create New coupon
                        </h3>
                        <button
                            onClick={toggleModal}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formik.handleSubmit} className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label htmlFor="code" className="block mb-2 text-sm font-medium text-gray-900 ">coupon Code</label>
                                <input
                                    type="text"
                                    name="code"
                                    id="code"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.code}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon code ex:sr25..."
                                />

                                {formik.errors.code && formik.touched.code ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.code}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900 ">Discount</label>
                                <input
                                    type="number"
                                    name="amount"
                                    id="amount"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.amount}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon Amount Discount from 0 : 100"
                                />

                                {formik.errors.amount && formik.touched.amount ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.amount}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="en_description" className="block mb-2 text-sm font-medium text-gray-900 ">Coupon Description English <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="en_description"
                                    id="en_description"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.en_description}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon Description ex:coupon Description in English..."
                                    rows="4"
                                ></textarea>

                                {formik.errors.en_description && formik.touched.en_description ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.en_description}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="ar_description" className="block mb-2 text-sm font-medium text-gray-900 ">Coupon Description Arabic <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="ar_description"
                                    id="ar_description"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ar_description}
                                    className="bg-gray-50 border text-right border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="...كتابة وصف للكوبون بالغة العربية"
                                    rows="4"
                                ></textarea>

                                {formik.errors.ar_description && formik.touched.ar_description ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ar_description}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900">Coupon Type English</label>
                                <div>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            id="discount"
                                            name="en_status"
                                            value="discount"
                                            checked={formik.values.en_status === "discount"}
                                            onChange={formik.handleChange}
                                            className="mr-2"
                                        />
                                        <span>Discount</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="cashback"
                                            name="en_status"
                                            value="cashback"
                                            checked={formik.values.en_status === "cashback"}
                                            onChange={formik.handleChange}
                                            className="mr-2"
                                        />
                                        <span>Cashback</span>
                                    </label>
                                </div>
                                {formik.errors.en_status && formik.touched.en_status ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formik.errors.en_status}
                                    </div>
                                ) : ''}
                            </div>

                            <div className="col-span-2 border-t-2 pt-2 border-blue-gray-200  text-right">
                                <label className="block mb-2 text-sm font-medium text-gray-900">نوع الكوبون بالعربية</label>
                                <div>
                                    <label className="inline-flex items-center align-top cursor-pointer">
                                        <span>خصم</span>
                                        <input
                                            type="radio"
                                            id="خصم"
                                            name="ar_status"
                                            value="خصم"
                                            checked={formik.values.ar_status === "خصم"}
                                            onChange={formik.handleChange}
                                            className="ml-2"
                                        />
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <span>كاش باك</span>
                                        <input
                                            type="radio"
                                            id="كاش باك"
                                            name="ar_status"
                                            value="كاش باك"
                                            checked={formik.values.ar_status === "كاش باك"}
                                            onChange={formik.handleChange}
                                            className="ml-2"
                                        />
                                    </label>
                                </div>
                                {formik.errors.ar_status && formik.touched.ar_status ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formik.errors.ar_status}
                                    </div>
                                ) : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="link" className="block mb-2 text-sm font-medium text-gray-900 ">Coupon Link</label>
                                <input
                                    type="text"
                                    name="link"
                                    id="link"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.link}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type a Link hear... ex: http://www.example.com"
                                />

                                {formik.errors.link && formik.touched.link ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.link}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="expire" className="block mb-2 text-sm font-medium text-gray-900">Expiration Date</label>
                                <input
                                    type="date"
                                    id="expire"
                                    name="expire"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.expire}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                />
                                {formik.errors.expire && formik.touched.expire ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formik.errors.expire}
                                    </div>
                                ) : null}
                            </div>

                            <div className="mb-4 border-r-2 pe-1 border-blue-gray-200">
                                <label className="block mb-2 text-sm font-medium text-gray-900">Locations <span className='text-red-600 font-extrabold '>*</span></label>
                                {locations.map(location => (
                                    <div key={location._id} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`location-${location._id}`}
                                            name={`location-${location._id}`}
                                            value={location._id}
                                            onChange={handleChangeLocation}
                                            checked={selectedLocations.includes(location._id)}
                                            className="text-primary-600 cursor-pointer rounded focus:ring-primary-600 h-4 w-4 mr-2"
                                        />
                                        <label htmlFor={`location-${location._id}`} className="text-gray-900">{location.name}</label>
                                    </div>
                                ))}
                            </div>

                            <div className="mb-4">
                                <label className="block mb-2 text-sm font-medium text-gray-900">Categories <span className='text-red-600 font-extrabold '>*</span></label>
                                {category?.map(category => (
                                    <div key={category._id} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`category-${category._id}`}
                                            name={`category-${category._id}`}
                                            value={category._id}
                                            onChange={handleChangeCategory}
                                            checked={selectedCategory.includes(category._id)}
                                            className="text-primary-600 cursor-pointer rounded focus:ring-primary-600 h-4 w-4 mr-2"
                                        />
                                        <label htmlFor={`category-${category._id}`} className="text-gray-900">{category?.name?.en}</label>
                                    </div>
                                ))}
                            </div>

                            <div className="mb-4 border-t-2 pt-2 border-blue-gray-200">
                                <label className="block mb-2 text-sm font-medium text-gray-900">Brands <span className='text-red-600 font-extrabold '>*</span></label>
                                {brand?.map(brand => (
                                    <div key={brand._id} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`brand-${brand._id}`}
                                            name={`brand-${brand._id}`}
                                            value={brand._id}
                                            onChange={handleChangeBrand}
                                            checked={selectedBrand.includes(brand._id)}
                                            className="text-primary-600 cursor-pointer rounded focus:ring-primary-600 h-4 w-4 mr-2"
                                        />
                                        <label htmlFor={`brand-${brand._id}`} className="text-gray-900">{brand?.name?.en}</label>
                                    </div>
                                ))}
                            </div>


                            <div className="col-span-2">
                                <label htmlFor="likeCount" className="block mb-2 text-sm font-medium text-gray-900 ">Like Count</label>
                                <input
                                    type="number"
                                    name="likeCount"
                                    id="likeCount"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.likeCount}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon Like Count is default: 0"
                                />

                                {formik.errors.likeCount && formik.touched.likeCount ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.likeCount}</div> : ''}
                            </div>


                            <div className="col-span-2">
                                <label htmlFor="dislikeCount" className="block mb-2 text-sm font-medium text-gray-900 ">DisLike Count</label>
                                <input
                                    type="number"
                                    name="dislikeCount"
                                    id="dislikeCount"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.dislikeCount}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon DisLike Count is default: 0"
                                />

                                {formik.errors.dislikeCount && formik.touched.dislikeCount ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.dislikeCount}</div> : ''}
                            </div>


                        </div>

                        <button type="submit" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                            Add new coupon
                        </button>

                        {error ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{error}</div> : ''}
                    </form>
                </div>
            </div>
        </div>


        {/* Main modal update */}
        <div
            id="crud-modal-update"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-full max-w-md max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Update Coupon
                        </h3>
                        <button
                            onClick={toggleModalUpdate}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formikUpdate.handleSubmit} className="p-4 md:p-5">

                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label htmlFor="code" className="block mb-2 text-sm font-medium text-gray-900 ">coupon Code</label>
                                <input
                                    type="text"
                                    name="code"
                                    id="code"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.code}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateCouponData?.code || "not exist code" || "Type coupon code ex:sr25..."}
                                />

                                {formikUpdate.errors.code && formikUpdate.touched.code ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.code}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900 ">Discount</label>
                                <input
                                    type="number"
                                    name="amount"
                                    id="amount"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.amount}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateCouponData?.amount || "Type coupon Amount Discount from 0 : 100"}
                                />

                                {formikUpdate.errors.amount && formikUpdate.touched.amount ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.amount}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="en_description" className="block mb-2 text-sm font-medium text-gray-900 ">Coupon Description English</label>
                                <textarea
                                    name="en_description"
                                    id="en_description"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.en_description}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateCouponData?.description?.en || "Type coupon Description ex:coupon Description in English..."}
                                    rows="4"
                                ></textarea>

                                {formikUpdate.errors.en_description && formikUpdate.touched.en_description ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.en_description}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="ar_description" className="block mb-2 text-sm font-medium text-gray-900 ">Coupon Description Arabic</label>
                                <textarea
                                    name="ar_description"
                                    id="ar_description"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ar_description}
                                    className="bg-gray-50 border text-right border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateCouponData?.description?.ar || "...كتابة وصف للكوبون بالغة العربية"}
                                    rows="4"
                                ></textarea>

                                {formikUpdate.errors.ar_description && formikUpdate.touched.ar_description ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ar_description}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900">Coupon Type English</label>
                                <div>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            id="discount"
                                            name="en_status"
                                            value="discount"
                                            checked={formikUpdate.values.en_status === "discount"}
                                            // checked={updateCouponData?.status?.en && formikUpdate.values.en_status === "discount"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>Discount</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="cashback"
                                            name="en_status"
                                            value="cashback"
                                            checked={formikUpdate.values.en_status === "cashback"}
                                            // checked={updateCouponData?.status?.en && formikUpdate.values.en_status === "cashback"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>Cashback</span>
                                    </label>
                                </div>
                                {formikUpdate.errors.en_status && formikUpdate.touched.en_status ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formikUpdate.errors.en_status}
                                    </div>
                                ) : ''}
                            </div>

                            <div className="col-span-2 border-t-2 pt-2 border-blue-gray-200  text-right">
                                <label className="block mb-2 text-sm font-medium text-gray-900">نوع الكوبون بالعربية</label>
                                <div>
                                    <label className="inline-flex items-center align-top cursor-pointer">
                                        <span>خصم</span>
                                        <input
                                            type="radio"
                                            id="خصم"
                                            name="ar_status"
                                            value="خصم"
                                            checked={formikUpdate.values.ar_status === "خصم"}
                                            // checked={updateCouponData?.status?.ar && formikUpdate.values.ar_status === "خصم"}
                                            onChange={formikUpdate.handleChange}
                                            className="ml-2"
                                        />
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <span>كاش باك</span>
                                        <input
                                            type="radio"
                                            id="كاش باك"
                                            name="ar_status"
                                            value="كاش باك"
                                            checked={formikUpdate.values.ar_status === "كاش باك"}
                                            // checked={updateCouponData?.status?.ar && formikUpdate.values.ar_status === "كاش باك"}
                                            onChange={formikUpdate.handleChange}
                                            className="ml-2"
                                        />
                                    </label>
                                </div>
                                {formikUpdate.errors.ar_status && formikUpdate.touched.ar_status ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formikUpdate.errors.ar_status}
                                    </div>
                                ) : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="link" className="block mb-2 text-sm font-medium text-gray-900 ">Coupon Link</label>
                                <input
                                    type="text"
                                    name="link"
                                    id="link"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.link}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateCouponData?.link || "not exist link" || "Type a Link hear... ex: http://www.example.com"}
                                />

                                {formikUpdate.errors.link && formikUpdate.touched.link ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.link}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="expire" className="block mb-2 text-sm font-medium text-gray-900">Expiration Date</label>
                                <input
                                    type="date"
                                    id="expire"
                                    name="expire"
                                    placeholder={updateCouponData?.expire || ''}
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.expire}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                />
                                {formikUpdate.errors.expire && formikUpdate.touched.expire ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formikUpdate.errors.expire}
                                    </div>
                                ) : null}
                            </div>

                            <div className="mb-4 border-r-2 pe-1 border-blue-gray-200">
                                <label className="block mb-2 text-sm font-medium text-gray-900">Locations</label>
                                {locations.map(location => (
                                    <div key={location._id} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`location-${location._id}`}
                                            name={`location-${location._id}`}
                                            value={location._id}
                                            onChange={handleChangeLocation}
                                            checked={selectedLocations.includes(location._id)}
                                            className="text-primary-600 cursor-pointer rounded focus:ring-primary-600 h-4 w-4 mr-2"
                                        />
                                        <label htmlFor={`location-${location._id}`} className="text-gray-900">{location.name}</label>
                                    </div>
                                ))}
                            </div>

                            <div className="mb-4">
                                <label className="block mb-2 text-sm font-medium text-gray-900">Categories</label>
                                {category?.map(category => (
                                    <div key={category._id} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`category-${category._id}`}
                                            name={`category-${category._id}`}
                                            value={category._id}
                                            onChange={handleChangeCategory}
                                            checked={selectedCategory.includes(category._id)}
                                            className="text-primary-600 cursor-pointer rounded focus:ring-primary-600 h-4 w-4 mr-2"
                                        />
                                        <label htmlFor={`category-${category._id}`} className="text-gray-900">{category?.name?.en}</label>
                                    </div>
                                ))}
                            </div>

                            <div className="mb-4 border-t-2 pt-2 border-blue-gray-200">
                                <label className="block mb-2 text-sm font-medium text-gray-900">Brands</label>
                                {brand?.map(brand => (
                                    <div key={brand._id} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`brand-${brand._id}`}
                                            name={`brand-${brand._id}`}
                                            value={brand._id}
                                            onChange={handleChangeBrand}
                                            checked={selectedBrand.includes(brand._id)}
                                            className="text-primary-600 cursor-pointer rounded focus:ring-primary-600 h-4 w-4 mr-2"
                                        />
                                        <label htmlFor={`brand-${brand._id}`} className="text-gray-900">{brand?.name?.en}</label>
                                    </div>
                                ))}
                            </div>


                            <div className="col-span-2">
                                <label htmlFor="likeCount" className="block mb-2 text-sm font-medium text-gray-900 ">Like Count</label>
                                <input
                                    type="number"
                                    name="likeCount"
                                    id="likeCount"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.likeCount}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateCouponData?.likeCount || "Type coupon Like Count is default: 0"}
                                />

                                {formikUpdate.errors.likeCount && formikUpdate.touched.likeCount ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.likeCount}</div> : ''}
                            </div>


                            <div className="col-span-2">
                                <label htmlFor="dislikeCount" className="block mb-2 text-sm font-medium text-gray-900 ">DisLike Count</label>
                                <input
                                    type="number"
                                    name="dislikeCount"
                                    id="dislikeCount"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.dislikeCount}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder={updateCouponData?.dislikeCount || "Type coupon DisLike Count is default: 0"}
                                />

                                {formikUpdate.errors.dislikeCount && formikUpdate.touched.dislikeCount ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.dislikeCount}</div> : ''}
                            </div>


                        </div>

                        <button
                            // disabled={!(formikUpdate.isValid && formikUpdate.dirty)}
                            type='submit'
                            className="text-white inline-flex items-center bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
                        >

                            {/* <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg> */}
                            Update Coupon
                        </button>

                        {errorUpdate ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{errorUpdate}</div> : ''}
                    </form>
                </div>
            </div>
        </div>



        {/* delete model */}
        <div
            id="popup-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal"
                        onClick={toggleModelDel}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg
                            className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                        </svg>
                        <h3 className="mb-6 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Move this Coupon to the Recycle Bin ?
                        </h3>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                            onClick={submitDeleteCoupon}
                        >
                            Yes, I'm sure
                        </button>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            onClick={toggleModelDel}
                            className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            No, cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </>
}
