import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BallTriangle } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default function FavoriteBrand() {

    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [error, setError] = useState(null)
    const [errorUpdate, setErrorUpdate] = useState(null)

    const [dropdownOpenButton, setDropdownOpenButton] = useState(false);
    const [dropdownOpenButton2, setDropdownOpenButton2] = useState(false);
    const toggleDropdownButton1 = () => {
        setDropdownOpenButton(!dropdownOpenButton);
    };

    const toggleDropdownButton2 = () => {
        setDropdownOpenButton2(!dropdownOpenButton2);
    };

    const handleInputChange = (event) => { // Function to handle input change
        setSearchQuery(event.target.value); // Update searchQuery state with input value
    };

    async function getBrandsFav(searchQuery) {

        return await axios.get(`${process.env.REACT_APP_BACK_END}brand/all/favorite/data?search=${searchQuery}`)
    }

    let { isLoading, data, refetch } = useQuery(['BrandsFav', searchQuery], () => getBrandsFav(searchQuery), {
        cacheTime: 9000000
    })

    let allBrandsFav = []

    if (data?.data?.message == "success") {

        allBrandsFav = data?.data?.brandsFav
    }

    return <>

        <Helmet>
            <meta name='description' content='Favorite BrandsFav Page' charSet="utf-8" />
            <title>Favorite BrandsFav</title>
        </Helmet>


        <div className="p-6 pe-8 overflow-y-auto rounded-t-xl bg-blue-gray-100 dark:bg-gray-900 flex justify-between align-middle">
            <div className="">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative mt-1">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <i className='fa fa-search w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden="true"></i>
                    </div>
                    <input type="search"
                        id="table-search"
                        className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-48 sm:w-56 lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search for Favorite Brands..."
                        value={searchQuery} // Bind value to searchQuery state
                        onChange={handleInputChange} // Call handleInputChange on input change
                    />
                </div>
            </div>

        </div>

        <div className="relative overflow-y-auto md:w-full sm:w-screen shadow-md sm:rounded-lg">

            {
                isLoading ? <div className="loading">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#199A8E"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassNameclassName=""
                        visible={true}
                    />
                </div> : allBrandsFav?.length === 0 ? <div className='flex justify-center align-middle p-12 text-teal-800'>
                    <h1 className='font-bold'>not found result try again</h1>
                </div> :
                    <table className="text-center w-full text-sm rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    #
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={toggleDropdownButton1}>
                                        Name
                                        <span className='ms-2'> {dropdownOpenButton ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={toggleDropdownButton1}>
                                        Most Used
                                        <span className='ms-2'> {dropdownOpenButton ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={toggleDropdownButton1}>
                                        Most Followed
                                        <span className='ms-2'> {dropdownOpenButton ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Description
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Locations
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Categories
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Image
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Link
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Brand ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Updated By
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {allBrandsFav?.map((brand, index) => <tr key={brand._id} className="text-center bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                                <th scope="row" className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {index + 1}
                                </th>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {brand.name.en}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {brand.mostUsed}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {brand.mostFollowed}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {brand.description.en}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {brand?.location?.map((location, index) => <h6 key={location._id} className='mb-2'>{location.name}</h6>)}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {brand?.category?.map((category, index) => <div key={index}>
                                        <h6 className='mb-2 text-left'>
                                            <span className='text-teal-700'>EN:</span> {category?.name.en}
                                        </h6>
                                        <h6 className='text-right mb-2'>
                                            <span className='text-teal-700'>العربية :</span> {category?.name.ar}
                                        </h6>
                                    </div>)}
                                </td>
                                <td className="px-3 py-4">
                                    <img src={brand?.image} className='w-14' alt={brand.name.en} />
                                </td>
                                <td className="px-3 py-4 font-medium whitespace-nowrap dark:text-white">
                                    <Link to={brand.link} target='_blank' className="text-blue-500 hover:text-blue-700">
                                        {brand.link}
                                    </Link>
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {brand._id}
                                </td>
                                <td className="px-3 py-4">
                                    {brand?.createdBy?.fullName.split(' ')?.slice(0, 2)?.join(' ') || "never yet Created"}
                                </td>
                                <td className="px-3 py-4">
                                    {brand?.updatedBy?.fullName.split(' ')?.slice(0, 2)?.join(' ') || "never yet updated"}
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
            }

        </div>


    </>
}
