import React from 'react'
import Navbar from '../Navebar/Navbar.jsx'

export default function Layout() {
    return <>


        <div className="container-xxl">

            <Navbar />

        </div >

    </>
}

