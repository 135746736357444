import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { createContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export let UserContext = createContext()

export default function UserContextProvider(props) {

    const [refresh_token, setRefresh_token] = useState(null)
    const [userData, setUserData] = useState(null)



    async function getLoggedUser() {

        try {

            let decoded = {}

            if (localStorage.getItem('refresh_token') !== null) {

                decoded = jwtDecode(localStorage.getItem('refresh_token'))


                let response = await axios.get(`${process.env.REACT_APP_BACK_END}user/loggedUser/data`,
                    {
                        headers: {
                            authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                        }

                    })

                let dataOfUser = {}

                if (response.data.message == "success") {


                    let allData = response?.data?.user[0]

                    // Appending fields to dataOfUser
                    dataOfUser.fullName = allData.fullName;
                    dataOfUser.joined = allData.joined;
                    dataOfUser.email = allData.email;
                    dataOfUser.confirmAccount = allData.confirmAccount;
                    dataOfUser.image = allData.image;


                    localStorage.setItem('userAccount', JSON.stringify(dataOfUser))

                }

            } else {

                return <Navigate to={'/login'} />
            }

        } catch (error) {

            // console.log(error);

        }

    }


    useEffect(() => {
        getLoggedUser()
    }, [])


    return <UserContext.Provider value={{ refresh_token, setRefresh_token, setUserData, userData }}>
        {props.children}
    </UserContext.Provider>
}
