import axios from 'axios';
import React, { useState } from 'react'
import { BallTriangle } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';


export default function UserDeleted() {

    const [searchQuery, setSearchQuery] = useState(""); // State for search query

    const [dropdownOpenButton, setDropdownOpenButton] = useState(false);
    const toggleDropdownButton1 = () => {
        setDropdownOpenButton(!dropdownOpenButton);
    };

    const handleInputChange = (event) => { // Function to handle input change
        setSearchQuery(event.target.value); // Update searchQuery state with input value
    };

    async function getAllUsers(searchQuery) {

        return await axios.get(`${process.env.REACT_APP_BACK_END}user?isDeleted=true&search=${searchQuery}`,
            {
                headers: {
                    authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                }
            })
    }

    let { isLoading, data, refetch } = useQuery(['AllUsersDel', searchQuery], () => getAllUsers(searchQuery), {
        cacheTime: 9000000
    })


    let allUsers = []

    if (data?.data?.message == "success") {

        allUsers = data?.data?.users
    }

    const toggleModelDel = () => {
        const modalDelete = document.getElementById('popup-modal');
        modalDelete.classList.toggle('hidden');
        modalDelete.setAttribute('aria-hidden', modalDelete.classList.contains('hidden'));
    }


    // Function to submit update user
    async function submitRestoreUser(userId) {

        try {

            const formData = new FormData();
            formData.append('isDeleted', 'false');

            const response = await axios.put(
                `${process.env.REACT_APP_BACK_END}user/admin/${userId}`,
                formData,
                {
                    headers: {
                        Authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            // console.log(response.data);

            await refetch();
            toast.success('user Restore successfully!');
        } catch (error) {
            // console.error('Error:', error?.response?.data);
            toast.error(error?.response?.data?.message);
            // setError(error?.response?.data?.message)
        }
    }


    // delete user
    const [deleteUserId, setDeleteUserId] = useState(null);

    // Function to handle update button click
    const handleDeleteClick = (user) => {
        setDeleteUserId(user?._id); // Set the ID of the user being updated
        toggleModelDel(); // Show the update modal
    };

    // Function to submit update user
    async function submitDeleteUser() {

        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BACK_END}user/${deleteUserId}`,
                {
                    headers: {
                        authorization: `${process.env.REACT_APP_BEARER_KEY}${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();
            toggleModelDel()
            toast.success('user delete successfully!');
        } catch (error) {
            // console.error('Error:', error?.response?.data?.message);
            toast.error(error?.response?.data?.message);
        }
    }

    return <>

        <div className="p-6 pe-8 overflow-y-auto rounded-t-xl bg-blue-gray-100 dark:bg-gray-900 flex justify-between align-middle">
            <div className="">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative mt-1">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <i className='fa fa-search w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden="true"></i>
                    </div>
                    <input type="search"
                        id="table-search"
                        className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-48 sm:w-56 lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search for users Deleted..."
                        value={searchQuery} // Bind value to searchQuery state
                        onChange={handleInputChange} // Call handleInputChange on input change
                    />
                </div>
            </div>

        </div>

        <div className="relative overflow-y-auto md:w-full sm:w-screen shadow-md sm:rounded-lg">

            {
                isLoading ? <div className="loading">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#199A8E"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassNameclassName=""
                        visible={true}
                    />
                </div> : allUsers.length === 0 ? <div className='flex justify-center align-middle p-12 text-teal-800'>
                    <h1 className='font-bold'>not found result try again</h1>
                </div> :
                    <table className="text-center overflow-y-auto w-full text-sm rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    #
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={toggleDropdownButton1}>
                                        Full Name
                                        <span className='ms-2'> {dropdownOpenButton ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Email || Phone Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    joined
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    confirmAccount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Image
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    user ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Updated By
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Restore
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Delete
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {allUsers.map((user, index) => <tr key={user._id} className="text-center bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {index + 1}
                                </th>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.fullName}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.email ? user.email :
                                        <p><span className='text-teal-500 me-1'>{user.countryCode}:</span> {user.phoneNumber} </p>
                                    }
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.status}
                                </td>
                                <td className="px-6 py-4 text-teal-700 font-extrabold whitespace-nowrap dark:text-white">
                                    {new Date(user.joined)?.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric' // Change '2-digit' to 'numeric' for full year
                                    })}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.confirmAccount ? "Yes" : "No"}
                                </td>
                                <td className="px-6 py-4">
                                    <img src={user.image} className='w-14' alt={user.name} />
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user._id}
                                </td>
                                <td className="px-6 py-4">
                                    {user?.createdBy?.fullName?.split(' ')?.slice(0, 2)?.join(' ') || "never yet Created"}
                                </td>
                                <td className="px-6 py-4">
                                    {user?.updatedBy?.fullName?.split(' ')?.slice(0, 2)?.join(' ') || "never yet Updated"}
                                </td>
                                <td className="px-6 py-4 mx-auto">
                                    <button
                                        // onClick={toggleModalUpdate}
                                        onClick={() => submitRestoreUser(user._id)}
                                        type="button"
                                        className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:focus:ring-yellow-900">
                                        Restore
                                    </button>
                                </td>
                                <td className="px-6 py-4 mx-auto">
                                    <button
                                        // onClick={toggleModelDel}
                                        onClick={() => handleDeleteClick(user)}
                                        data-modal-target="popup-modal"
                                        data-modal-toggle="popup-modal"
                                        type="button"
                                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                                        Delete
                                    </button>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
            }

        </div>


        {/* delete model */}
        <div
            id="popup-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal"
                        onClick={toggleModelDel}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg
                            className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                        </svg>
                        <h3 className="mb-6 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Permanetly delete this User ?
                        </h3>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                            onClick={submitDeleteUser}
                        >
                            Yes, I'm sure
                        </button>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            onClick={toggleModelDel}
                            className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            No, cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </>
}
