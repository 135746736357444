import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FallingLines } from 'react-loader-spinner'
import { UserContext } from '../../Context/UserContext.js';
import { Helmet } from 'react-helmet';
import { jwtDecode } from "jwt-decode";


export default function LoginWithEmail() {

    let { setRefresh_token, setUserData } = useContext(UserContext)
    let navigate = useNavigate()
    const [error, setError] = useState(null)
    const [logging, setLogging] = useState(false)

    async function submitLogin(value) {
        setLogging(true)

        let response = await axios.post(`${process.env.REACT_APP_BACK_END}auth/loginEmail`, value)
            .catch((err) => {
                setError(err.response.data.message)
                setLogging(false)
            })


        // console.log(response.data);

        const decoded = jwtDecode(response.data.refresh_token);

        if (decoded.role == 'User') {
            setLogging(false)
            setError("You are not allowed to Login Dashboard")
        }

        if (response.data.message === 'success' && decoded.role != 'User') {
            setLogging(false)
            localStorage.setItem('refresh_token', response.data.refresh_token)
            setRefresh_token(response.data.refresh_token)
            setUserData(response.data.refresh_token)
            navigate('/')
        }

    }

    let validateSchema = Yup.object({
        email: Yup.string().email('email is invalid').required('email is required'),
        password: Yup.string().min(6, 'Password must with character and minLength 6').required('password is required'),
    })

    let formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        }, validationSchema: validateSchema,
        onSubmit: submitLogin
    })


    // to show text whe type password
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };



    return <>

        <Helmet>
            <meta name='description' content='login Page' charSet="utf-8" />
            <title>Login With Email</title>
        </Helmet>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

            <form className="space-y-6" onSubmit={formik.handleSubmit}>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                        Email address
                    </label>
                    <div className="mt-2">
                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            id='email'
                            name='email'
                            type='email'
                            autoComplete="email"
                            className="block w-full rounded-md border-0 py-1.5 font-semibold text-gray-900 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {formik.errors.email && formik.touched.email ? <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.email}</div> : ''}

                    </div>
                </div>

                <div>
                    <div className="flex items-center justify-between">
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                            Password
                        </label>
                        <div className="text-sm">
                            <Link to={'/forgetPassword'} className="font-semibold text-indigo-600 hover:text-indigo-500">
                                Forgot password?
                            </Link>
                        </div>
                    </div>
                    <div className="mt-2 relative">

                        <i
                            className={`absolute right-2 top-2.5 ${showPassword ? "fa-solid fa-eye text-gray-700 cursor-pointer"
                                : "fa-solid fa-eye-slash cursor-pointer"}`}
                            onClick={togglePasswordVisibility}
                        ></i>

                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            id='password'
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            className="block w-full rounded-md border-0 py-1.5 font-semibold text-gray-900 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {formik.errors.password && formik.touched.password ? <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.password}</div> : ''}

                    </div>
                </div>

                {logging ? <button type='button' className='flex w-full justify-center rounded-md'>

                    <FallingLines
                        width="50"
                        radius="9"
                        color="#3b35b4"
                        visible={true}
                        ariaLabel="falling-circles-loading"
                    />
                </button> :
                    <div>
                        <button

                            // disabled
                            disabled={!(formik.isValid && formik.dirty)}
                            type='submit'
                            className="flex w-full justify-center cursor-pointer rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold leading-6 disabled:cursor-default disabled:bg-indigo-400 disabled:text-gray-200 text-white shadow-sm active:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Sign in
                        </button>
                    </div>
                }

                {error ? <div className="py-3 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{error}</div> : ''}


            </form>

        </div>
    </>

}
