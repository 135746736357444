import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useQuery } from 'react-query';
import axios from 'axios';

export default function Barchar() {
    async function getCoupons() {
        return await axios.get(`${process.env.REACT_APP_BACK_END}coupon/toDashboard`);
    }

    const { data } = useQuery('Coupons', getCoupons, {
        cacheTime: 9000000
    });

    let allCoupons = [];

    if (data?.data?.message === "success") {
        allCoupons = data?.data?.coupon;
    }

    return (
        <div className="container py-1.5 h-full w-full">
            <h1 className='text-center text-teal-500 font-extrabold text-xl xl:text-3xl'>Coupon Code Data</h1>

            <ResponsiveBar
                data={allCoupons}
                keys={['amount', 'usedCount', 'likeCount', 'dislikeCount']}
                indexBy="code"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'set1' }} // Change the color scheme if needed
                groupMode="grouped" // To display bars side by side
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 7,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Code of coupon',
                    legendPosition: 'middle',
                    legendOffset: 35,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Number of coupon data',
                    legendPosition: 'middle',
                    legendOffset: -45,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]]
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={e => `${e.id}: ${e.formattedValue} in code: ${e.indexValue}`}
            />
        </div>
    );
}
